import React from 'react';
import sidebarImg from '../images/sidebar-img.png';
import mainLogo from '../images/mainLogo.png';
import mintWhite from '../images/livemint-logo-white.png';
import '@fontsource/poppins';
import '../css/Style.css';

const CORPORATE_STATUS = process.env.REACT_APP_CORPORATE_STATUS === 'true';

const LoginSidebar = ({ forInstitutional="false" }) => {
  const isInstitutional = 
    typeof forInstitutional === "boolean" 
      ? forInstitutional 
      : forInstitutional === "true";
  const redirectToHome = () => {
    window.location.href = window.location.origin;
  };

  return (
    <>
      { (CORPORATE_STATUS && isInstitutional) ? (
        <div className="col-md-4 div-left corporate-sidebar">
          <div className='login-sidebar-main'>
            <div className="corporate-sidebar-main">
              <img className="img-fluid"  src={mintWhite} />
              <h1>CORPORATE SUBSCRIPTION</h1>
            </div>
            <div className='mainlogo-home'>
              <a className="navbar-brand-1" target="_blank" rel="noopener noreferrer">
                <img src={mainLogo} onClick={redirectToHome} alt="HT Logo"/>
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-md-4 div-left div-left-side-img">
          <div className='login-sidebar-main'>
            <div className="vertical-text">
              <p className="subscribenowtitle">
                <b>Subscribe now!</b> To get credible news at incredible price!
              </p>
            </div>
            <div className='mainlogo-home'>
              <a className="navbar-brand-1" target="_blank" rel="noopener noreferrer">
                <img src={mainLogo} onClick={redirectToHome} alt="HT Logo"/>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginSidebar;
