import React, { useState, useEffect } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/poppins';
import './../css/Style.css';
import OtpInput from 'react-otp-input';
import otpnjTimer from './../images/otpnj-timer.svg';
import { Modal, Button, Form } from 'react-bootstrap';
import mobileSecurity from './../images/mobile-security.png';

// import PageLoader from '../subComponents/PageLoader';
const OtpTime = 30;
function OTPValidation({ show, handleClose, sendOtp, validateOtp, createNewLead, otpSource, otpType }) {
    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [timerDelay, setTimerDelay] = useState(OtpTime);

    useEffect(() => {
        // Reset timer only when the modal opens
        if (show) {
            setTimerDelay(OtpTime);
        }
    }, [show]);

    useEffect(() => {
        // Timer countdown logic
        let timer;
        if (timerDelay > 0 && show) {
            timer = setTimeout(() => setTimerDelay(timerDelay - 1), 1000);
        }
        return () => clearTimeout(timer);
    }, [timerDelay, show]);

    const handleValidateOtp = async () => {
        const result = await validateOtp(otp);
        console.log("result === ", result);
        if (result && result.success) {
            // otpType ? sessionStorage.setItem("stg_otpEmailValidated", true) : 
            sessionStorage.setItem("stg_otpValidated", true);
            handleClose(true);
            // console.log("otpType == ", otpType);
            // otpType === false && createNewLead();
            createNewLead()
            setOtp('');
        } else {
            setError(result.message);
            // otpType ? sessionStorage.removeItem("stg_otpEmailValidated") : 
            sessionStorage.removeItem("stg_otpValidated");
        }
    };

    const handleChange = (otp) => setOtp(otp);

    const resendOtp = async () => {
        const result = await sendOtp();
        if (result.success) {
            setTimerDelay(OtpTime);
        }else{
            handleClose(false);
            setOtp('');
            // otpType ? sessionStorage.removeItem("stg_otpEmailValidated") : 
            sessionStorage.removeItem("stg_otpValidated");

        }
    };

    const handleCloseOtp = async (status) => {
        handleClose(false);
        setError('');
        setOtp('');
    }

    return (
        <Modal show={show} onHide={() => handleCloseOtp(false)} centered>
            <Modal.Header className="bg-corporate" closeButton>
                <div className="bg-corporate d-flex justify-content-center pb-3">
                    <img className="img-widht-12" src={mobileSecurity} />
                </div>
               
                {/* <Modal.Title className="title">OTP Verification</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
            <div className="d-flax text-center">
                <h4 className="alert-heading corporate-title">Mobile OTP Verification</h4>
                <p className="sub-title">
                    Enter the OTP you received to <span className="phone-number">{otpSource}</span>
                </p>
                <Form.Group controlId="formBasicOTP">
                    <div className="d-flex justify-content-between">
                    <Form.Label className="otp-label">Enter 4-digit OTP</Form.Label>
                    {!timerDelay ? (
                        <div className="label-next text-right" data={timerDelay}>
                            <a onClick={resendOtp} className="text-right">Resend OTP</a>      
                        </div>
                    ) : (
                        <div className="label-next text-right" data={timerDelay}>
                            <img
                                src={otpnjTimer}
                                height="20px"
                                width="15px"
                                className="mr-1"
                                alt="Timer"
                            />
                            00:{timerDelay < 10 ? `0${timerDelay}` : timerDelay}
                        </div>
                    )}
                    </div>
                    <div className="d-flex justify-content-center">
                    <OtpInput
                        value={otp}
                        onChange={handleChange}
                        numInputs={4}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        separator={<span> ---- </span>}
                        autoComplete="one-time-code"
                        containerStyle={false}
                        disabledStyle={true}
                        className="otp-input-b"
                        inputStyle={{
                            width: "5em",
                            height: "3em",
                            textAlign: "center",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            border: "1px solid #CDCDCD",
                            borderRadius: "8px",
                        }}
                    />
                     </div>
                </Form.Group>
                {error && <p style={{ color: 'red' }}>{error}</p>}

                <div className="corporate-otp-btn justify-content-center">
                <Button
                    disabled={otp.length !== 4}
                    variant="primary"
                    onClick={handleValidateOtp}
                >
                    Validate OTP
                </Button>
                </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default OTPValidation;

