import React, { useState, useEffect } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/poppins';
import './../css/Style.css';
import OtpInput from 'react-otp-input';
import {validateCorporateEmail} from "../common/commonFuntion";
import otpnjTimer from './../images/otpnj-timer.svg';
import openEmail from './../images/open-email.png';
import openOtpEmail from './../images/otp-email.png';
import { Modal, Button, Form } from 'react-bootstrap';
import UserService from "../services/UserService";
import PageLoader from '../subComponents/PageLoader';

const OtpTime = 30;

const EailVerify = ({ show, handleClose, otpEmail, resendOtp, updateMail }) => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(OtpTime);
  const [error, setError] = useState("");

  // useEffect(() => {
  //   let countdown;
  //   setEmail(otpEmail);
  //   if (show && timer > 0) {
  //     countdown = setInterval(() => {
  //       setTimer((prev) => prev - 1);
  //     }, 1000);
  //   }
  //   return () => clearInterval(countdown);
  // }, []);

  useEffect(() => {
    let countdown;
    setEmail(otpEmail);
  
    if (show) {
      countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev > 0) return prev - 1; // Decrement timer
          clearInterval(countdown);     // Stop interval when timer reaches 0
          return prev;
        });
      }, 1000);
    }
  
    return () => clearInterval(countdown); // Cleanup interval on unmount or when modal closes
  }, [show]); // Depend only on `show`
  

  const handleChange = (otp) => setOtp(otp);

  const handleValidateOtp = async () => {
      try {
          const response = await UserService.verifiyOtpFresh(otp, email, "SSP");

          if (response && response.data) {
            setOtp('');
            setEmail('');
            setError("");
            handleClose(false); 
            updateMail();
          } else if (!response.success) {
              setError(response.message || "Invalid OTP. Please try again.");
          } else {
              setError("Something went wrong. Please try again.");
          }
      } catch (err) {
          console.error("Error in login validateAndHandleOtp:", err);

          if (err.response && err.response.data) {
              if (err.response.status === 429) {
                  setError(
                      "You have entered incorrect OTP multiple times. Please try after 30 minutes."
                  );
              } else {
                  setError(err.response.data.message || "Something went wrong!");
              }
          } else {
              setError("Something went wrong!");
          }
      }
  };

  const handleResendOtp = () => {
    setTimer(OtpTime);
    resendOtp(true);
  };

  const handleCloseOtp = (status) => {
    setOtp("");
    setError("");
    handleClose(status);
  };

  return (
    <Modal show={show} onHide={() => handleCloseOtp(false)} centered>
      {/* { loading && <PageLoader/> } */}
      <Modal.Header className="bg-corporate" closeButton />
      <div className="bg-corporate d-flex justify-content-center pb-3"><img className="img-widht-10" src={openOtpEmail} /></div>
      <Modal.Body>
          <div className="d-flax text-center">
            <h4 className="alert-heading corporate-title">Email OTP Verification</h4>
            <p className="sub-title">
              Enter the OTP you received at{" "}
              <span className="phone-number">{email}</span>
            </p>
            <Form.Group className="ooo" controlId="formBasicOTP">
              <div className="d-flex justify-content-between">
              <Form.Label className="otp-label">Enter 4-digit OTP</Form.Label>
              {timer ? (
                <div className="label-next text-right">
                  <img
                    src={otpnjTimer}
                    height="20px"
                    width="15px"
                    className="mr-1"
                    alt="Timer"
                  />
                  00:{timer < 10 ? `0${timer}` : timer}
                </div>
              ) : (
                <div className="label-next text-right">
                  <a onClick={handleResendOtp} className="text-right">
                    Resend OTP
                  </a>
                </div>
              )}
              </div>
              <div className="d-flex justify-content-center">
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={4}
                isInputNum
                shouldAutoFocus
                separator={<span> ---- </span>}
                autoComplete="one-time-code"
                disabledStyle={true}
                className="otp-input-b"
                inputStyle={{
                  width: "5em",
                  height: "3em",
                  textAlign: "center",
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  border: "1px solid #CDCDCD",
                  borderRadius: "8px",
                }}
              />
              </div>
            </Form.Group>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <div className="corporate-otp-btn">
              <Button
                variant="primary"
                onClick={handleCloseOtp}
              >
                Change Email
              </Button>
              <Button
                disabled={otp.length !== 4}
                variant="primary"
                onClick={handleValidateOtp}
              >
                Validate OTP
              </Button>
            </div>
          </div>
      </Modal.Body>
    </Modal>
  );
};

export default EailVerify;
