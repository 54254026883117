import React, { useState, useEffect } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/poppins';
import './../css/Style.css';
import profileUpdate from '../images/profile-update.png';
import { Modal, Button, Form } from 'react-bootstrap';

function CorporateMailUpdatePopup({ show, handleClose, mobile, email, updateEmailAddress, defultAlert, emailUpdated }) {
    const [error, setError] = useState('');

    useEffect(() => {
        console.log("show = ", show);
    });
    const handleValidateOtp = async () => {
        const result = await updateEmailAddress();
        console.log("result === ", result);
        if (result && result.success) { 
            emailUpdated();
            handleClose(true);
            window.location = `/?mobile=${mobile}`;
        } else {
            defultAlert(result.message); 
        }
    };

    const handleCloseOtp = async (status) => {
        handleClose(status);
    }
    const handleCloseOtpDigital = async (status) => {
        handleClose(status);
        window.location = `/`;
    }
    const handleLogin = async (status) => {
        handleClose(status);
        window.location = `/?mobile=${mobile}`;
    }

    return (
       
        <Modal show={show} onHide={() => handleCloseOtp(false)} centered>
            <Modal.Header className="bg-corporate" closeButton>
                <div className="bg-corporate d-flex justify-content-center pb-3">
                    <img className="img-widht-12" src={profileUpdate} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flax text-center text-dark" role="alert">
                    
                    <h4 className="alert-heading corporate-title">
                        {/* <i className="fa fa-info-circle"></i> */}
                        Unlock Your Corporate Offer!
                    </h4>
                    <p>
                    Oops! We don’t have your corporate email. Update it now to unlock our exclusive corporate subscription offer!{' '}
                    </p>
                    <span className="phone-number font-weight-bold">{email}</span>
                    {/* <hr /> */}
                    {/* <p className="mb-0">OR You can log in as you</p> */}
                </div>

                <div className="corporate-otp-btn">
                    <Button onClick={() => handleLogin(false)}>
                        Back To Login
                    </Button>
                    <Button onClick={handleValidateOtp}>
                        Update & Login
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
            
      );
      
}

export default CorporateMailUpdatePopup;

