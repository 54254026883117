import React, { useState, useEffect } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/poppins';
import './../css/Style.css';
import OtpInput from 'react-otp-input';
import {validateCorporateEmail, setUtmSource} from "../common/commonFuntion";
import otpnjTimer from './../images/otpnj-timer.svg';
import openEmail from './../images/open-email.png';
import openOtpEmail from './../images/otp-email.png';
import { Modal, Button, Form } from 'react-bootstrap';
import UserService from "../services/UserService";
import PageLoader from '../subComponents/PageLoader';

const OtpTime = 30;

const CorporateMailVerify = ({ show, handleClose, focusInput = null, }) => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(OtpTime);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let countdown;
    if (otpSent && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(countdown);
  }, [otpSent, timer]);

  const handleChange = (otp) => setOtp(otp);

  const handleSendOtp = async (e) => {
    e.preventDefault();
    const validationError = await validateCorporateEmail(email);
    if (validationError) {
      setError(validationError);
    } else {
      const res = await saveEmail(email);
      console.log("res === ", res);
      if(res){
        setError("");
        setOtpSent(true);
        setTimer(OtpTime); // Reset the timer
      }
    }
  };

  const saveEmail = async (emailId) =>{
      setLoading(true);
      try {
        const otpResponse = await UserService.generateFreshEamilOtp(emailId, "SSP");
  
        if (otpResponse?.success) {
          setError("");
          setLoading(false);
          return true;
  
        }else if(otpResponse?.message){
          if(otpResponse.message === "Email already registered"){
            setError( <>
            This email is already associated with an account! Please{" "}
            <a style={{textDecoration: "underline"}} href="javascript:void(0)" onClick={() => handleCloseOtp(false)}>log in</a>{" "} on our website with your mobile number.
          </>);
          }else{
            setError(otpResponse.message);
          }
          setLoading(false);
          return false;
        }
        else{
          setError(`${otpResponse.message || "Something went wrong"}`);
          setLoading(false);
          return false;
        }
  
      } catch (error) {
        console.error("Error in checkMobileExistWithEvent:", error);
        setError(error);
        setLoading(false);
        return false;
      }
  
  }

  const handleValidateOtp = async () => {
      try {
          const response = await UserService.verifiyOtpFresh(otp, email, "SSP");

          if (response && response.data) {
              sessionStorage.setItem("stg_otpEmailValidated", true);
              sessionStorage.setItem("stg_verifiedEmail", email);
              handleClose(true); // Assuming handleClose is passed as a prop or defined elsewhere
              setOtp('');
              setError("");
              let utmParams = setUtmSource("utm_source");
              window.location = `/?msg=newCustomer&utm_source=Corporate${utmParams}`;
          } else if (!response.success) {
              setError(response.message || "Invalid OTP. Please try again.");
              sessionStorage.removeItem("stg_otpEmailValidated");
          } else {
              setError("Something went wrong. Please try again.");
              sessionStorage.removeItem("stg_otpEmailValidated");
          }
      } catch (err) {
          console.error("Error in validateAndHandleOtp:", err);

          if (err.response && err.response.data) {
              if (err.response.status === 429) {
                  setError(
                      "You have entered incorrect OTP multiple times. Please try after 30 minutes."
                  );
              } else {
                  setError(err.response.data.message || "Something went wrong!");
              }
          } else {
              setError("Something went wrong!");
          }

          sessionStorage.removeItem("stg_otpEmailValidated");
      }
  };

  const handleResendOtp = () => {
    setTimer(OtpTime);
    saveEmail(email);
  };

  const handleGoBack = () => {
    setOtpSent(false);
    // setEmail("");
    setOtp("");
    setError("");
  };

  const handleCloseOtp = (status) => {
    handleClose(false);
    setOtp("");
    setError("");
    focusInput?.("tel");
  };

  return (
    <Modal show={show} onHide={() => handleCloseOtp(false)} centered className="custom-modal-width">
      { loading && <PageLoader/> }
      <Modal.Header className="bg-corporate" closeButton />
      <div className="bg-corporate d-flex justify-content-center pb-3"><img className="img-widht-10" src={otpSent ? openOtpEmail: openEmail} /></div>
      <Modal.Body>
        {!otpSent ? (
          <div className="d-flax text-center">
            <h4 className="alert-heading corporate-title">Email Verification</h4>
            <Form.Group controlId="formBasicOTP" style={{ marginBottom: "2px", display: "grid", justifyItems: "center" }}>
              <Form.Label className="otp-label">
                Please enter your corporate email Id
              </Form.Label>
              <input
                className="form-control"
                type="email"
                name='email'
                id='email'
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ padding: "8px", width: "70%" }}
              />
            </Form.Group>
            {error && <p className="emailError">{error}</p>}
            <div className="corporate-otp-btn d-flex justify-content-center">
            <Button
              disabled={!email}
              variant="primary"
              onClick={handleSendOtp}
              style={{ marginTop: "15px" }}
            >
              Send OTP
            </Button>
            </div>
          </div>
        ) : (
          <>
          <div className="d-flax text-center">
            <h4 className="alert-heading corporate-title">Email OTP Verification</h4>
            <p className="sub-title">
              Enter the OTP you received at{" "}
              <span className="phone-number">{email}</span>
            </p>
            <Form.Group className="ooo" controlId="formBasicOTP">
              <div className="d-flex justify-content-between">
              <Form.Label className="otp-label">Enter 4-digit OTP</Form.Label>
              {timer ? (
                <div className="label-next text-right">
                  <img
                    src={otpnjTimer}
                    height="20px"
                    width="15px"
                    className="mr-1"
                    alt="Timer"
                  />
                  00:{timer < 10 ? `0${timer}` : timer}
                </div>
              ) : (
                <div className="label-next text-right">
                  <a onClick={handleResendOtp} className="text-right">
                    Resend OTP
                  </a>
                </div>
              )}
              </div>
              <div className="d-flex justify-content-center">
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={4}
                isInputNum
                shouldAutoFocus
                separator={<span> ---- </span>}
                autoComplete="one-time-code"
                disabledStyle={true}
                className="otp-input-b"
                inputStyle={{
                  width: "5em",
                  height: "3em",
                  textAlign: "center",
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  border: "1px solid #CDCDCD",
                  borderRadius: "8px",
                }}
              />
              </div>
            </Form.Group>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <div className="corporate-otp-btn">
              <Button
                variant="primary"
                onClick={handleGoBack}
              >
                Change Email
              </Button>
              <Button
                disabled={otp.length !== 4}
                variant="primary"
                onClick={handleValidateOtp}
              >
                Validate OTP
              </Button>
            </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CorporateMailVerify;
