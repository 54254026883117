import React, { Component } from 'react';  
import PaymentOffer from './PaymentOffer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPercent } from '@fortawesome/free-solid-svg-icons';


class PaymentSummary extends Component {  
    constructor(props) {
        super(props);
        this.state = 
        {
            width: window.innerWidth,
            showPaymentOffer: false,
            doNotShowSummary: true
        };
      }

      componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
      }
      
      handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
      };

      updateShowPaymentOffer = (status) => 
      {  
         sessionStorage.setItem("stg_mo_redirectFrom","Click on Payment Offer Link From Checkout Page")

        this.setState({ showPaymentOffer: status });
        this.moengagePaymentOffer_Clicked();
      }; 

      selectPaymentOfferL2= (value) => 
      {  
         this.props.onSelectPaymentL1(value);
      }

      showPaymentSummaryStatus() 
      {
         this.setState({ doNotShowSummary: false });  
      }

      hidePaymentSummaryStatus() 
      {
         this.setState({ doNotShowSummary: true });  
      }

      moengagePaymentOffer_Clicked()
      {
        
        if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
        {

         if(sessionStorage.getItem("createNewAccountActive")==="ON")
         {
            window.Moengage.track_event( "Acquisition  Payment Offers Clicked",
            {
               Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"),
               Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
               Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"), Plan_Duration: sessionStorage.getItem("stg_selectedPlan_Duration"),
               Plan_Price : sessionStorage.getItem("stg_selectedPlan_Price"),Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
               Mobile_Number: sessionStorage.getItem("acqPhoneNumber"),Registered: sessionStorage.getItem("stg_acq_userType")
            }
           )
         }
        else
         {
            window.Moengage.track_event( "Payment Offers Clicked",
            {
               Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"),
               Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
               Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"), Plan_Duration: sessionStorage.getItem("stg_selectedPlan_Duration"),
               Plan_Price : sessionStorage.getItem("stg_selectedPlan_Price"),Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
               Mobile_Number: sessionStorage.getItem("acqPhoneNumber"),Registered: sessionStorage.getItem("stg_acq_userType")
            }
           )
         }

        }
      }

   render() 
   {
        const { width } = this.state;
        const isMobile = width <= 500;
        var compPaymnetSummary = 
            <div>
               <div className="order-details">
                  <h5>Summary</h5>
                  <div id="details-box" className="">
                     <p>{this.props.planNameOnSummary}</p>
                     {sessionStorage.getItem('preAuth')==="ON" ? 
                        (
                           
                           <p className="final-price">
                              <span className="mr-1">Rs.</span>
                              {this.props.currentPlanPrice}
                           </p>
                           
                        ):
                        ( 
                           <p className="final-price">
                              <span className="mr-1">Rs.</span>
                              {this.props.actualPlanPrice}
                           </p>
                        )
                     } 
                  </div>
                  <table className="table border-0 mb-0">
                     <tbody>
                        {!this.props.couponNotAdded && sessionStorage.getItem('preAuth') !== "ON" && (
                           <tr className="total-coupon-savings">
                              <th className="text-left">
                                 <p className="mb-0">Coupon Savings: </p>
                              </th>
                              <td className="text-center"></td>
                              <td className="text-right">
                                 <div className="final-price">
                                    <p> - <i className="fas fa-rupee-sign"></i>{this.props.couponDiscountAmount}</p>
                                 </div>
                              </td>
                           </tr>
                        )}
                     </tbody>
                  </table>

                  <a onClick={() => this.updateShowPaymentOffer(true)} className="paymentLinkCh">
                     <FontAwesomeIcon className="offerIcon" icon={faPercent} /> 
                     <u>Payment Offers</u>
                  </a>
               </div>
               <PaymentOffer  
                        showModalPopup={this.state.showPaymentOffer}  
                        onPopupClose={this.updateShowPaymentOffer}  
                        onSelectPaymentL2={this.selectPaymentOfferL2}
               ></PaymentOffer> 
            </div>

        if (isMobile) 
        {
            return (
                <div>
                  { this.state.doNotShowSummary 
                  ?     
                     <p onClick={this.showPaymentSummaryStatus.bind(this)} style={{textAlign: "center"}}> 
                        <a className="text-decoration-none blackClourV2"> 
                           <i onClick={this.showPaymentSummaryStatus.bind(this)} className="fa fa-chevron-up"></i>
                        </a>
                     </p>
                  :
                     <div> 
                        <p onClick={this.hidePaymentSummaryStatus.bind(this)} style={{textAlign: "center"}}> 
                           <a className="text-decoration-none blackClourV2"> 
                              <i onClick={this.hidePaymentSummaryStatus.bind(this)} className="fa fa-chevron-down"></i>
                           </a>
                        </p>
                        {compPaymnetSummary}
                     </div>
                  }                           
               </div> 
            );
        } 
        else 
        {
            return (
                <div>
                   {compPaymnetSummary}
                </div>          
                );
        }
      };
}
export default (PaymentSummary); 
