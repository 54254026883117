import React, { Component, createRef } from 'react';
import UserService from '../services/UserService';
import OtpPageNJ from '../components/OtpPageNJ';
import Footer from '../components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../css/Style.css';
import './../css/StyleV2.css';
import './../css/animate.css';
import flagCircle from './../images/india_flag_icon.png';
import googlePlay from './../images/GetItOnGooglePlay_Badge_Web_color_English.png';
import edit_write_pen_icon from './../images/edit_write_pen_icon.svg';
import publication from './../images/publication.png';
import bottomNewsPaper from './../images/bottomNewsPapger.png';
import 'react-toastify/dist/ReactToastify.css';
// import dotenv from 'dotenv';
import Home from './HomeNJ';
import { ToastContainer, toast } from 'react-toastify';
import ReactGA from 'react-ga';
import {getUtmSource, setUtmSource} from "../common/commonFuntion"
import AuthService from '../services/AuthService';

import Carousel from "react-bootstrap/Carousel";
import banner1 from './../images/banner-1.png';
import banner2 from './../images/banner-2.png';
import banner3 from './../images/banner-3.png';
import firstPage from './../images/first-page.png';
import mainLogo from '../images/mainLogo.png';
import allLogos from '../images/all-logos.png';
import mintIcon from '../images/home-icons/minticon.svg';
import HTIcon from '../images/home-icons/Hindustan_Times_logo1.png';
import HTHIcon from '../images/home-icons/lh-logo-desk.webp';
import MintLogo from "../images/home-icons/corporate-subscription.jpg";
import Mintbundle from "../images/home-icons/mint-bundle.png";
import PopupContactus from '../subComponents/PopupContactus';
import CorporateMailVerify from '../subComponents/CorporateMailVerify';
import PageLoader from '../subComponents/PageLoader';
const CORPORATE_STATUS = process.env.REACT_APP_CORPORATE_STATUS === 'true';

// import Captcha from '../subComponents/Captcha';
// import LoginHeader from '../subComponents/LoginHeader';


// dotenv.config();
let refresh=0;
// const LoginHeader = React.lazy(() => import('../subComponents/LoginHeaderNew'));


ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);
export default class MobileLoginNJ extends Component
{

  enterMobile = () => toast.warn("Mobile Number should not be more than 10 digits", {
    position: toast.POSITION.TOP_CENTER
 });
  
  constructor(props) {
    super(props);
    this.state = {
      value:'',
      phoneNumber: '',
      show: false,
      showMobileFirst: true,
      showMobile: true,
      loading: false,
      showHome:false,
      counter:30,
      delay:true,
      showPopupAlertState:false,
      isAndroidApp: false,
      corporateStatus: process.env.REACT_APP_CORPORATE_STATUS, 
      otpEmailValidated: false
    };
    this.inputRefs = {
      tel: createRef()
    };
    this.focusInput = this.focusInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.editHanlde=this.editHanlde.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
    this.goBackPage = this.goBackPage.bind(this);
    this.successHome=this.successHome.bind(this);
    this.useInterval=this.useInterval.bind(this);
    this.redirectToHome = this.redirectToHome.bind(this);
  }

  focusInput(field) {
    if (this.inputRefs[field] && this.inputRefs[field].current) {
      this.inputRefs[field].current.focus();
    }
  }


  redirectToHome() {
    window.location.href = window.location.origin;
  }

  handleChange(e)
  {
    this.setState({ phoneNumber: e.target.value.replace(/\D/g, '') });
  }


  goBackPage() {
    this.setState({ phoneNumber: '' });
    this.setState({ showMobile: true })
    this.setState({ show: false })
  }

  successHome() 
  {
    this.setState({ show: false })
    this.setState({ showMobileFirst: false })
    this.setState({ showMobile: false })
    this.setState({ showHome: true })
    sessionStorage.removeItem("showHeader");
    sessionStorage.setItem("showHeader","ON")
  }

  editHanlde() 
  {
    this.setState({ show: false })
    this.setState({ showMobileFirst: true })
    this.setState({ phoneNumber: "" })
    sessionStorage.removeItem("stg_status");
    sessionStorage.removeItem("stg_mobile_number")
  }

  moengageHTreaders_Viewed()
  {
    if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
    {
      getUtmSource();
      window.Moengage.track_event('HTreaders Viewed',
      {
        Campaign_Source: sessionStorage.getItem("stg_utm_source"), 
        Campaign_Name :sessionStorage.getItem("stg_utm_name"),
        Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), 
        Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom")

      })
    }
  }

  moengageNextButton_Click()
  {
    if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
    {
      getUtmSource();
      window.Moengage.add_unique_user_id("mo_htread_"+sessionStorage.getItem("stg_mobile_number"));
      window.Moengage.track_event('Before OTP Next Button Click',
      {
        Campaign_Source: sessionStorage.getItem("stg_utm_source"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
        Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Registration_Status: sessionStorage.getItem("stg_status"), 
        Mobile_Number: sessionStorage.getItem("stg_mobile_number"), Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom")

      })
    }
  }

  moengageAqu_NewCustomer_Click()
  {
    if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
    {
      getUtmSource();
      sessionStorage.setItem("stg_mo_redirectFrom","Clicked on New Customer Button on HT Reader.com")
      window.Moengage.track_event('Acquisition New Customer Button Click',
      {
        Campaign_Source: sessionStorage.getItem("stg_utm_source"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
        Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom")

      })
    }
  }

  moengageAqu_BuyNow_Click()
  {
    if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
    {
      getUtmSource();
      sessionStorage.setItem("stg_mo_redirectFrom","Clicked on BuyNow Button on  Carousel in login page HT Reader.com")
      window.Moengage.track_event('Acquisition BuyNow Button on  Carousel in login page',
      {
        Campaign_Source: sessionStorage.getItem("stg_utm_source"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
        Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom")
      })
    }
  }

  componentDidMount()
  {
    getUtmSource();
    sessionStorage.setItem("stg_Corporate_status", false);
    ReactGA.ga('send','pageview','/login');
    window.Moengage.destroy_session();
    AuthService.getAuth("htprint", "htprint");
    sessionStorage.removeItem("redirect_loginPage");
    if(!sessionStorage.getItem("stg_mo_htReader_count"))
    {
      sessionStorage.setItem("stg_mo_redirectFrom","HT Reader.com")
    }
    sessionStorage.removeItem('stg_isWhatsappChecked')
    sessionStorage.removeItem("acqPhoneNumber")
    sessionStorage.removeItem("stg_nda_adressDetails")
    sessionStorage.removeItem("stg_loggedInHeader")
    sessionStorage.removeItem("acqAccountNotAvailable_via_Login")
    sessionStorage.removeItem("createNewAccountActive")
    sessionStorage.removeItem("stg_acq_exitingUser")
    sessionStorage.removeItem("stg_acq_freshUser")
    sessionStorage.removeItem("stg_emailAdress_Checkout_updated")
    sessionStorage.removeItem('orderDetails')
    sessionStorage.removeItem("mo_acq_numberStatus")
    sessionStorage.removeItem("stg_acq_otp")
    sessionStorage.removeItem("stg_acq_userType")
    
    this.handleURLWhatsApp();
    const isWebView = (() => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /wv/.test(userAgent) || /Android.*Version\/[\d.]+.*Chrome\/[\d.]+ Mobile Safari\/[\d.]+/.test(userAgent);
    })();
  
    const isAndroid = /Android/.test(navigator.userAgent);

    this.setState({
        isAndroidApp: isWebView && isAndroid,
    });

  }

  handleURLWhatsApp = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const whatsAppNo = queryParams.get('mobile');
    const utmSource = queryParams.get('utm_source')?.trim();
    const utmSourceTrim = utmSource && utmSource === "WhatsApp" ? utmSource : null
    // Call your function with the parameter if needed
    if (whatsAppNo) {
      this.setState(
        { phoneNumber: this.trimMobileNumber(whatsAppNo) }, // Update state
        () => {
          this.showotp(utmSourceTrim); // Call the function after state update
        }
      );
    }else{
      if (window.location.hash === "#exclusive-corporate-deals") {
        this.setState({ otpEmailValidated: true });
      }
    }
  }

  trimMobileNumber = (number) => {
    // Remove spaces, dashes, and parentheses
    const cleanedNumber = number.replace(/[\s()-]/g, '').trim();
    return cleanedNumber;
  }

  useInterval()
  {
    sessionStorage.setItem("stg_status","Registered");
    this.moengageNextButton_Click();
    clearInterval(this.Interval)
    this.setState({ counter: 30 })
    this.setState({ delay: true })
    this.Interval=setInterval(() => { 
      let timer=this.state.counter;
      if(timer > 0)
      {
        this.setState({
          counter: this.state.counter - 1
        })
      }
      else if(this.state.counter===0)
      {
        this.setState({ delay: false })
        clearInterval(this.Interval)
        
      }
    },1000)}


    

    createAccount = () =>
    {
      getUtmSource();
      this.moengageAqu_NewCustomer_Click();
      ReactGA.ga('send','pageview','/NewCustomerFromLoginPage_NewCustomerBtn')
      sessionStorage.setItem("stg_Corporate_status", false);
      sessionStorage.setItem("redirect_loginPage","true")
      let utmParams = setUtmSource();
      window.location = `/?msg=newCustomer${utmParams}`;
    }

    buyNowClick=()=>
    {
      getUtmSource();
      ReactGA.ga('send','pageview','/NewCustomerFromLoginPage_BuyNowBtn');
      sessionStorage.setItem("stg_Corporate_status", false);
      window.location = '/?msg=newCustomer&utm_source=Banner';
    }

    buyCorporateOffer=()=>
    {
      getUtmSource();
      ReactGA.ga('send','pageview','/NewCustomerFromLoginPage_CORPORATE_BOOKING');
      sessionStorage.setItem("stg_Corporate_status", true);
      this.setState({ otpEmailValidated: true });
    }
  

  showotp = (utmSource=null) => 
  {
    sessionStorage.setItem("stg_status","Not Registered");
    sessionStorage.setItem("stg_mobile_number",this.state.phoneNumber);
    sessionStorage.setItem("stg_mo_redirectFrom","Click on Show OTP Next Button")

    if(this.state.phoneNumber.length >10)
    {
        this.enterMobile();
        sessionStorage.setItem("stg_status","In Vaild Number");
        this.moengageNextButton_Click();
    }
    else
    {
      if (this.state.phoneNumber) 
      {
        this.setState({ loading: true });
        var source = "SSP";
        UserService.generateOtp(this.state.phoneNumber,source).then(response => 
        {
          // console.log("response ==== ", response);
          if (response.success && response.success === true){
            this.setState({ showMobile: true })
            this.setState({ loading: false });
            this.setState({ show: true })
            this.setState({ showMobileFirst: false })
            this.useInterval();
            ReactGA.ga('send','pageview','/otp')
            let utmAction = utmSource ? `Redirect source ${utmSource}` : 'Click on Next Button';
            ReactGA.event({
              category: 'User',
              action: utmAction
            });
          }else{
            window.location.href = '/newCustomer';
            sessionStorage.setItem("acqPhoneNumber",this.state.phoneNumber)
            sessionStorage.setItem("acqAccountNotAvailable_via_Login","ON")
            this.moengageNextButton_Click();
            this.setState({ show: false });
            this.setState({ showMobileFirst: true })
            this.setState({ showMobile: true });
            this.setState({ loading: false });
          }
        }).catch(err => 
          {
            // console.log("errerr === ", err);
            window.location.href = '/newCustomer';
            sessionStorage.setItem("acqPhoneNumber",this.state.phoneNumber)
            sessionStorage.setItem("acqAccountNotAvailable_via_Login","ON")
            this.moengageNextButton_Click();
            this.setState({ show: false });
            this.setState({ showMobileFirst: true })
            this.setState({ showMobile: true });
            this.setState({ loading: false });

          })
      } 
      else 
      {
        this.setState({ show: false });
        this.setState({ showMobile: true });
        this.setState({ loading: false });
        sessionStorage.setItem("stg_status","Invaild Number");
        this.moengageNextButton_Click();
      }
    }
  }

  enterPressed(event) 
  {
    var code = event.keyCode || event.which;
    if (code === 13 && this.state.phoneNumber.length>=10) 
    {
      this.showotp();
    }
  }

  logout()
  {
    this._logout()
  }

  _logout() {
    this.setState({
      auth: {
        ...this.state.auth,
        loggedIn: false,

      }
    })
  }

  showcontactUsPopUp = () => 
  {  
      this.setState({ showPopupAlertState: true });  
  };

  closecontactUsPopUp = ()=>{
    this.setState({ showPopupAlertState: false }); 
  }

  handleClose = (success) => {
    this.setState({ otpEmailValidated: false });
    // Add further handling logic here if needed
  };

  render() {
    const { loading, isAndroidApp, corporateStatus } = this.state;
    const appClass = isAndroidApp ? 'pt-2-rem' : '';
    return (
      <div >
        { loading && <PageLoader/> }
          {/* <LoginHeader/> */}
        {this.state.showMobile && 
          
          <div className='main-first'>
              <div className="side-img">
                <div className="col-md-6 div-left div-left-home-img">
                  <div className='mainlogo-home'>
                    <a className="navbar-brand-1" target="_blank">
                          <img className="img-fluid" src={mainLogo} onClick={this.redirectToHome} alt="main Logo"/></a>  
                  </div>
                  {/* <img className='div-left-img' src={firstPage} alt="First slide" /> */}
                </div>
                <div className="col-md-6 div-right">
                  <div className="row justify-content-center rm-row-margin">
                      <div className="col-md-10">
                        <a className="navbar-brand-2" target="_blank">
                          <img style={{ width: "15%" }} src={mintIcon} onClick={this.redirectToHome} alt="main Logo"/>
                          <img style={{ width: "42%" }} src={HTIcon} onClick={this.redirectToHome} alt="main Logo"/>
                          <img style={{ width: "28%" }} src={HTHIcon} onClick={this.redirectToHome} alt="main Logo"/>
                        </a>    
                      </div>
                    <div className="col-md-10">
                      <div>
                      {corporateStatus ? (
                        <div className='holographic-container'>
                        <div className="subscription-card holographic-card">
                            {/* <div className="logo"> */}
                                <img onClick={this.buyCorporateOffer} src={MintLogo} className="brand-name" alt='Buy Mint: Corporate Subscription' />
                                <img onClick={this.buyCorporateOffer} src={Mintbundle} className="mint-bundle img-fluid" alt='Buy Mint: Corporate Subscription' />
                                <button onClick={this.buyCorporateOffer} className="booking-button">Click Here To Buy Corporate Subscription 
                                <FontAwesomeIcon icon={faArrowCircleRight} style={{marginLeft: "5px"}} />
                                </button>
                            {/* </div> */}
                            {/* <p className="description">Mint: Corporate Subscription</p> */}
                        </div>
                        {/* <div className="row justify-content-center">
                          <button onClick={this.buyCorporateOffer} className="buy-now-cta">
                          Buy Mint Corporate Subscription Now
                          </button>
                        </div> */}
                        </div>
                      ) : (
                        <>
                        <div id="demo" className="carousel slide" data-ride="carousel">
                          <ul className="carousel-indicators">
                              <li
                                data-target="#demo"
                                data-slide-to="0"
                                className="active"
                              ></li>
                              <li data-target="#demo" data-slide-to="1"></li>
                          </ul>
                          <Carousel controls={false}>
                            <Carousel.Item interval={10000}>
                              <div className="carousel-item active">
                                      <img src={banner1} alt="First slide" />
                                  </div>
                              </Carousel.Item>
                              <Carousel.Item interval={10000}>
                                    <div className="carousel-item active">
                                      <img src={banner3} alt="Third slide" />
                                    </div>
                                  </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className="row justify-content-center">
                          <button onClick={this.buyNowClick} className="buy-now-cta">
                            Buy Now
                          </button>
                        </div>
                        </>
                      )}
                        
                      </div>
                    </div>

                    <div className="col-md-10 pt-2-rem">   
                        <div className="row popup_style-bg">
                          <div className="col-md-12 cm_box_design">
                            {/* <h6 className="text-center mb-4">Subscribe Now! To get print Newspaper delivery at your doorstep.</h6> */}
                              {!isAndroidApp && (
                                <div className="playStore">
                                  <a target="_blank" href="https://play.google.com/store/apps/details?id=com.hindustantimes.htreaders&pcampaignid=web_share">
                                    <img src={googlePlay} className="img-fluid" />
                                  </a>
                                </div>
                              )}
                              <div className={appClass}>
                                { this.state.showMobileFirst && 
                                  <div className="formV2">
                                    <div className="homeTitle">
                                      <p className ='blackClourV2 labelFontSize1V1 fontNJ text-center'>Existing Customer?</p>
                                      
                                    </div>
                                  {/* <Captcha/> */}
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text bg-white"><img src={flagCircle} className='flag fontNJ'/>&nbsp;+91</span>
                                    </div>
                                    <input ref={this.inputRefs.tel} type="tel" className="form-control inp-placeholder" maxLength={10} value={this.state.phoneNumber} onChange={this.handleChange} onKeyPress={this.enterPressed} placeholder="Enter Mobile Number"/>
                                  </div>
                                  <div className="getOtpBtn mt-3 mb-3">
                                      <a className="clickV2-alink" onClick={this.createAccount}>New Customer</a> 
                                      <div className='text-center'>                                      
                                        {this.state.phoneNumber.length<10? 
                                        (
                                        <button type="button" className="btn dislogin-btnV2 fontNJ login-next-btn">
                                        Next</button>
                                        ):(
                                        <button disabled={false} type="button" className="btn login-btnV2 fontNJ login-next-btn" onClick={() => this.showotp()}>
                                        Next</button> 
                                        )
                                      }
                                    </div>
                                  </div>
                                  
                                  <div>
                                  </div>
                                  </div>
                                }
                                  {this.state.show && 
                                      <div className="formV2">
                                        <p className ='blackClourV2 labelFontSize1V1 fontNJ text-center mob-nj'>Enter Registered Number</p>
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text"><img src={flagCircle} className='flag'/>&nbsp;+91</span>
                                          </div>
                                          <input type="text" className="form-control otp-input" value={this.state.value} readOnly placeholder={this.state.phoneNumber}/>
                                          <div className="input-group-append">
                                            <span className="input-group-text edit fontNJ resnedOtp" onClick={this.editHanlde}><img src={edit_write_pen_icon} className='edit-pen-icon' /></span>
                                          </div>
                                        </div>
                                        <OtpPageNJ newUser={this.createAccount} otpCallBack={this.goBackPage} updateOtpTimer={this.useInterval} style={{ "height": "100%" }} mobile={this.state.phoneNumber} gotpHome={this.successHome} otpTimer={this.state.counter} timerDelay={this.state.delay} />
                                      </div>
                                    }
                                    <ToastContainer />
                              </div>
                          </div>
                        </div>
                        
                        {/* <div className="bottom_contact">
                          <p data-toggle="modal" data-target="#contact_box" onClick={this.showcontactUsPopUp}><span>Contact Us</span></p>
                        </div> */}

                        {/* <PopupContactus  
                              showModalPopup={this.state.showPopupAlertState}  
                              onPopupClose={this.closecontactUsPopUp}  
                          ></PopupContactus>  */}
                        {/* <img className="productImgV2 mt-4" src={bottomNewsPaper} width="50%"/>  
                        <img className="productImgV2 mt-4" src={publication} width="50%"/>     */}
                        
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
          </div>
        }   
        {
          this.state.showHome &&  
          <Home userInfo={sessionStorage.getItem("userInfo")} />
          // <RedeemCoupon userInfo={sessionStorage.getItem("userInfo")} />
        }
        {
          this.state.otpEmailValidated &&  
          <CorporateMailVerify 
            show={this.state.otpEmailValidated} 
            handleClose={this.handleClose}
            focusInput={this.focusInput}
          />
        }


        </div>  
    );
  }
}