import React, { Component } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import '@fontsource/poppins';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../css/Style.css';
import './../css/StyleV3.css';
import UserService from '../services/UserService';
import {validateCorporateEmail} from "../common/commonFuntion";
import { schemeDescription, duplicateImageFilter } from '../services/schemeTextFilter';
import AllSchemes from './AllSchemes';
import NewSchemes from './NewSchemes';
import 'react-toastify/dist/ReactToastify.css';
import './../css/StyleV2.css';
import './../css/order.css';
import './../css/animate.css';
import { Tooltip, Button } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionActions from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OrderCreated from "../images/Orders/Order_Created.svg";
import SubscriptionReceivedActive from "../images/Orders/Subscription-Received-Active.svg";
import RenewalChannels from "../images/Orders/Renewal_channels.svg";
import SubscriptionApproved from "../images/Orders/Subscription-Approved.svg";
import SubscriptionApprovedActive from "../images/Orders/Subscription-Approved-Active.svg";
import PaymentStatus from "../images/Orders/Payment-Status.svg";
import PaymentStatusActive from "../images/Orders/Payment-Status-Active.svg";
import CouponsIssued from "../images/Orders/Coupons-Issued.svg";
import CouponsIssuedActive from "../images/Orders/Coupons-Issued-Active.svg";
import CouponsDispatch from "../images/Orders/Coupons-Dispatch.svg";
import CouponsDispatchActive from "../images/Orders/Coupons-Dispatch-Active.svg";
import NoDataFoundImg from "../images/No-data-found.png";
import eventScheduleIcon from "../images/ReleaseCoupon/calendar_date_event_schedule_icon.svg";
import couponIcon from "../images/ReleaseCoupon/coupon_icon.svg";
import clsoedIcon from "../images/clsoedIcon.svg";
import LoggedInHeader from '../subComponents/LoggedInHeader';
import SideProfile from '../subComponents/SideProfile';
import CorporateOffers from '../subComponents/CorporateOffers';
import PopupComplaint from '../subComponents/PopupComplaint';
import OrderDetails from '../subComponents/OrderDetails';
import Moment from 'moment';
import PageLoader from '../subComponents/PageLoader';
import { ToastContainer, toast } from 'react-toastify';
import ReactGA from 'react-ga';
import VendorDetailsChange from '../components/VendorDetailsChange';
import CorporateDigitalPopup from '../subComponents/CorporateDigitalPopup';

const CORPORATE_STATUS = process.env.REACT_APP_CORPORATE_STATUS === 'true';
const digitalPublication = ["LM", "HD", "LM;Mint", "Mint;LM"];


let button;
let redeemBtn;
let buttonHeader;
let duplicateImageRemoved;
var printText = [];
var digitalText = [];
let collapseLoop=0;
let couponType;

var publicationNameArr = {'HT':'Hindustan Times','HH': 'Hindustan Hindi','LM':'Live Mint','Mint':'Mint'}

const stageInfo = {
   subscriptionReceived: {
      imgSrc: {
         'Inactive': OrderCreated,
         'Active': SubscriptionReceivedActive,
      },
      text: 'Subscription Received',
   },
   subscriptionApproved: {
      imgSrc: 
      {
         'Inactive': SubscriptionApproved,
         'Active': SubscriptionApprovedActive,
      },
      text: 'Subscription Approved',
   },
   paymentStatus: {
      imgSrc: 
      {
         'Inactive': PaymentStatus,
         'Active': PaymentStatusActive,
      },
      text: 'Payment Status',
   },
   couponsIssued: {
      imgSrc: 
      {
         'Inactive': CouponsIssued,
         'Active': CouponsIssuedActive,
      },
      text: 'Coupons Issued',
   },
   couponDispatch: {
      imgSrc: 
      {
         'Inactive': CouponsDispatch,
         'Active': CouponsDispatchActive,
      },
      text: 'Coupons Dispatch',
   }
 };
export default class Home extends Component {
   notFound = () => toast.warn("Some netowrk issues, Please try again", {
      position: toast.POSITION.TOP_CENTER
   });

   networkIssue = () => toast.warn("Some netowork issue, Please try again!", {
      position: toast.POSITION.TOP_CENTER
   });

   errorMsg = (errorMsg) => toast.warn(errorMsg, {
      position: toast.POSITION.TOP_CENTER
   });

   constructor(props) {
      super(props);
      this.state = {
         orders: JSON.parse(sessionStorage.getItem('orderDetails')).orders,
         vendor_name: JSON.parse(sessionStorage.getItem('orderDetails')).vendorName,
         vendor_mobile: JSON.parse(sessionStorage.getItem('orderDetails')).vendorMobile,
         reader_email: JSON.parse(sessionStorage.getItem('orderDetails')).email,
         reader_mobile: JSON.parse(sessionStorage.getItem('orderDetails')).mobile,
         isTimeOut: false,
         selectedDiv: 0,
         homePage: true,
         schemePage: false,
         NewSchemePage: false,
         CorporateSchemeFlag: false,
         openModalId: null,
         orderStages: {},
         comlaintOrderId: null,
         loading: false,
         clickedOrder: false,
         ordersLoading: false,
         showHomeDashbaord:true,
         showCheckoutModel:false,
         showCheckStatus:false,
         pageLoader:true,
         moengageRedeemRenew_Value: "Redeem Coupon Clicked",
         consent: false,
         showPopupAlertState: false,
         showCororateOffersPopup: false,
         hasButtonElement: false,
         selectedCorporateSchemes: {},
         forInstitutional: false,
         validDigitalOrder: false
      };
      this.onCollaps = this.onCollaps.bind(this);
      this.currentScheme = this.currentScheme.bind(this);
      this.goBackPage = this.goBackPage.bind(this);
      this.redirectToHome = this.redirectToHome.bind(this);
      this.validatedate=this.validatedate.bind(this);
      this.redeemCoupon=this.redeemCoupon.bind(this);
      this.handleLocationId = this.handleLocationId.bind(this);
      this.handleDivClick = this.handleDivClick.bind(this);
      this.closeOrderDetailsBox = this.closeOrderDetailsBox.bind(this);
      this.showNewSchemes = this.showNewSchemes.bind(this);
      this.showCororateOffers = this.showCororateOffers.bind(this);
      this.showCurrentSchemes = this.showCurrentSchemes.bind(this);
      
   }

   showcontactUsPopUp = (orderID) => {
      this.setState({ showPopupAlertState: true, comlaintOrderId: orderID });
   };
   
   closecontactUsPopUp = () => {
      this.setState({ showPopupAlertState: false, comlaintOrderId: null });
   };
   
   closeCororateOffers = () => {
      this.setState({ showCororateOffersPopup: false, selectedCorporateSchemes: {} });
   };

   fetchData = async (orderID) => {
      const { orderStages } = this.state;
      if (!orderStages[orderID]) {  // Only fetch if data is null
         UserService.fetchOrderStages(orderID).then(response => {
            // console.log("fetchOrderStages = ", response);
            if(response && response.data){
               this.setState((prevState) => ({
                  orderStages: {
                     ...prevState.orderStages,
                     [orderID]: response.data,
                  },
               }));
            }
            this.setState({ pageLoader: false });
         }).catch(err => {
            console.error("fetchOrderStages Error", err);
            this.setState({ pageLoader: false });
         });
      }
   };

   handleDivClick(key, orderID) {
      // console.log("orderID = ", orderID);
      // sessionStorage.setItem("stg_clicked_OrderId", orderID);
      this.fetchData(orderID);
      this.setState({ selectedDiv: key, openModalId: orderID });
   };

   closeOrderDetailsBox() {
      this.setState({ openModalId: null }, () => {
          // This callback ensures the state is updated before any further logic
         //  console.log('Modal closed, openModalId:', this.state.openModalId);
      });
  };

   validatedate(dateString){      
      let dateformat = /^(0?[1-9]|1[0-2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/;      
            
      // Match the date format through regular expression      
      if(dateString.match(dateformat)){      
          let operator = dateString.split('/');      
        
          // Extract the string into month, date and year      
          let datepart = [];      
          if (operator.length>1){      
              datepart = dateString.split('/');      
          }      
          let month= parseInt(datepart[0]);      
          let day = parseInt(datepart[1]);      
          let year = parseInt(datepart[2]);      
                
          // Create list of days of a month      
          let ListofDays = [31,28,31,30,31,30,31,31,30,31,30,31];      
          if (month==1 || month>2){      
              if (day>ListofDays[month-1]){      
                  ///This check is for Confirming that the date is not out of its range      
                  return false;      
              }      
          }else if (month==2){      
              let leapYear = false;      
              if ( (!(year % 4) && year % 100) || !(year % 400)) {      
                  leapYear = true;      
              }      
              if ((leapYear == false) && (day>=29)){      
                  return false;      
              }else      
              if ((leapYear==true) && (day>29)){
                  return false;      
              }      
          }      
      }else{
          return false;      
      }      
      return true;      
   } 

   updateCheckStatus = (status) => {  
      this.setState({ showCheckStatus: status });  
   }; 

   moengageHomePage_Viewed()
    {
      
      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {
         window.Moengage.track_event('Home Page Viewed',
        {
            Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"), 
            Campaign_Medium: sessionStorage.getItem("stg_utm_medium") ,  Registration_Status: sessionStorage.getItem("stg_status"), 
            Campaign_Name: sessionStorage.getItem("stg_utm_name"),Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom")
        }
        )
      }
   }

   moengageTabExpanded_Viewed()
    {
      
      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {
           window.Moengage.track_event('Tab Expanded',
        {
            Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"), 
            Campaign_Medium: sessionStorage.getItem("stg_utm_medium") ,  Registration_Status: sessionStorage.getItem("stg_status"), 
            Campaign_Name: sessionStorage.getItem("stg_utm_name"),Order_number: sessionStorage.getItem("stg_clicked_OrderId")
            ,Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom")
         })
      }
   }

   moengageRenewPlan_Clicked()
    {
      
      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {
      
            window.Moengage.track_event( "Renew Plan Clicked",
         {
            Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"), 
            Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Registration_Status: sessionStorage.getItem("stg_status"), 
            Campaign_Name: sessionStorage.getItem("stg_utm_name"),Order_Status: sessionStorage.getItem("stg_renew_clicked_order_status"),
            Order_Number: sessionStorage.getItem("stg_renew_clicked_order_id"),Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom")

         }
        )
      }
   }

   moengageRedeemCoupon_Clicked()
    {
      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {
            window.Moengage.track_event('Redeem Coupon Clicked',
         {
            Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"), 
            Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Registration_Status: sessionStorage.getItem("stg_status"), 
            Campaign_Name: sessionStorage.getItem("stg_utm_name"),Order_Status: sessionStorage.getItem("stg_renew_clicked_order_status"),
            Order_Number: sessionStorage.getItem("stg_renew_clicked_order_id"),Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom")


         }
        )
      }
   }

   updateOrderSession = (updatedOrders) => {
      // Get the current session value (JSON string)
      let orderDetailsOnly = sessionStorage.getItem('orderDetails');
  
      // Parse the string to convert it into an object
      if (orderDetailsOnly) {
         orderDetailsOnly = JSON.parse(orderDetailsOnly);
  
         // Modify the specific key (e.g., update age)
         if(updatedOrders && updatedOrders.length > 0){
            orderDetailsOnly.orders = updatedOrders;

            // Save the updated object back to sessionStorage
            sessionStorage.setItem('orderDetails', JSON.stringify(orderDetailsOnly));
            // console.log("Updated session value:", updatedOrders);
         }
      }
    };

   componentDidMount() {
      console.log("assadasdsa asda");
      ReactGA.ga('send', 'pageview', '/home');
      const sessionKeysToRemove = [
      "stg_clicked_OrderId",
      "stg_renew_clicked_order_status",
      "stg_renew_clicked_order_id",
      "stg_selectedPlan_Name",
      "stg_selectedPlan_Duration",
      "stg_selectedPlan_Price",
      "stg_emailAdress_Checkout_updated",
      "stg_existing_address_DeliverPage_updated",
      "stg_new_address_DeliverPage_updated"
      ];
      sessionKeysToRemove.forEach(key => sessionStorage.removeItem(key));
      sessionStorage.setItem("stg_Payment_Offer_Selected", "default");
      sessionStorage.setItem("stg_userJourneyType", "Renewal");

      this.moengageHomePage_Viewed();
      this.setState({ ordersLoading: true });

      UserService.fetchOrders(JSON.parse(sessionStorage.getItem('orderDetails')).id)
      .then(response => {
         if (response.data !== "") {
            this.setState({ orders: response.data, pageLoader: false });
            sessionStorage.setItem("first_publication", response.data[0].scheme.sapPublication);
            this.updateOrderSession(response.data);
            // Remove this block and call from sub-component instead
            // UserService.fetchAccountInfo(this.state.userInfo.mobile).then(response => {
            //   this.setState({ emailChange: response.data.email, editEmail: response.data.email });
            //   const locationId = response?.data?.location?.id;
            //   if (locationId !== undefined) {
            //     sessionStorage.setItem("stg_location_code", locationId);
            //   }
            //   this.setState({ ordersLoading: false });
            //   this.defaultCollaps();
            // }).catch(err => {
            //   console.error(err);
            //   this.setState({ pageLoader: false });
            // });

            this.setState({ ordersLoading: false });
            this.defaultCollaps();
         }
      })
      .catch(err => {
         console.error(err);
         this.setState({ pageLoader: false });
      });

      setTimeout(() => {
         this.setState({ isTimeOut: true });
      }, 60000 * 60);

      this.state.orders.forEach((obj, i) => {
         obj.open = false;
         obj.selectedOrder = false;
      });


   }

   // validOrderOnDigitalFn = async (schemPublicationData, mobile, email) => {
   //    console.log("schemPublicationData = ", schemPublicationData, " /email = ", email, " /mobile = ", mobile);
   //    if (digitalPublication.includes(schemPublicationData)) {
   //       try {
   
   //       if(email && mobile){
   //          const response = await UserService.validOrderOnDigital(email, mobile);
   //          console.log(response, " jjkkjkj333 ", response.data.message, " status = ", response.data.success);
         
   //          if (response?.data?.success) {
   //             this.setState({validDigitalOrder: false}); // Valid order, no action needed
   //             return true;
   //          } else if (response?.data?.message === "Subscription already exists!") {
   //             // console.log(" jjkkjkj ");
   //             this.closeCororateOffers();
   //             this.setState({validDigitalOrder: true}); 
   //             return false;
   //          }else {
   //                // Invalid order, show modal
   //             // defultAlert(`${response?.message || "Something went wrong. Please try again."}`);
   //             this.setState({validDigitalOrder: false}); 
   //             return true;
   //          }
   //       }else{
   //          this.setState({validDigitalOrder: false}); 
   //          return true;
   //       }
   //       } catch (err) {
   //          console.error("Error in validating digital order:", err);
   //          this.setState({validDigitalOrder: false}); 
   //          return true;
   //       }
   //    }else{
   //       this.setState({validDigitalOrder: false}); 
   //       return true;
   //    }
   // };

   updateCorporateAccount = async(email) => {
      // console.log("updateCorporateAccount === ", email);
      try {
         if(CORPORATE_STATUS) {
            const validationError = await validateCorporateEmail(email);
            // console.log("validationError = ", validationError);
            if (validationError) {
               this.setState({ CorporateSchemeFlag: false });
            } else {
               if(CORPORATE_STATUS){
                  this.setState({ CorporateSchemeFlag: true });
               }else{
                  this.setState({ CorporateSchemeFlag: false });
               }
            }
         }
      } catch (error) {
         this.setState({ CorporateSchemeFlag: false });
      }
   }

   isWithin90Days = (date) => {
      // console.log("date == ", date);
      
      const currentDate = new Date();
      const pastDate = new Date(date);
      // console.log("pastDate == ", pastDate);
      
      // Calculate the difference in time (in milliseconds)
      const timeDifference = currentDate - pastDate;
  
      // Convert time difference from milliseconds to days
      const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
      // console.log("dayDifference == ", dayDifference);
      return dayDifference < 90;
    }
//   gotoCouponsConfirmationPage() {
//         this.setState({ changeVendorDetailsShow: false })
//         this.setState({ homePage: true })
//     }

   gotoHomePage = () =>
   {
      this.setState({ changeVendorDetailsShow: false });
      this.setState({ homePage: true })
   }

   goBackPage() {
      this.setState({
          homePage: true,
          schemePage: false,
          NewSchemePage: false,
          showCororateOffersPopup: false,
          showCheckoutModel: false,
          showHomeDashbaord: true,
      });
   }

   defaultCollaps() 
   {
      let list = this.state.orders.map((obj,i) => {
      if (i === 0) 
         {
            obj.open = true;
         }
         return obj;
      });
      this.setState({ orders: list });
    
   }

   onCollaps(e) 
   {
      sessionStorage.setItem("stg_clicked_OrderId", e.target.id);
      this.moengageTabExpanded_Viewed();
   
      let id = e.target.id;
      let list = this.state.orders.map((obj,i) => {
         
         if (obj.id === id) {

            if (obj.open) {
               obj.open = false;

            } else {
               obj.open = true;
            }
         }
         return obj;
      });
      this.setState({ orders: list });
   }

   redeemCoupon(e)
    {
      // debugger
      var selectedPublicationValue=e.target.attributes.id.value;
      var orderID=e.target.attributes.dataorderid.value;
      var orderKey=e.target.attributes.dataorderkey.value;
      sessionStorage.setItem("stg_clicked_OrderId", orderID);
      sessionStorage.setItem("stg_mo_redirectFrom","Click on Redeem Coupon Button from HomePage")
      sessionStorage.removeItem("homeSelectedPublicationValue")
      sessionStorage.setItem("homeSelectedPublicationValue", selectedPublicationValue)
      sessionStorage.removeItem("orderPlanColor")
      sessionStorage.setItem("orderPlanColor", orderKey)
      this.moengageRedeemCoupon_Clicked()
      window.location.href = "/redeem";
   }

   async showCurrentSchemes (selectedSchemeData, publication) {
      try {
         this.setState({loading: true});
         // console.log("selectedSchemeData = ", selectedSchemeData);
         // console.log("forInstitutional current == ", publication, this.state.reader_mobile, this.state.reader_email);
         // if(publication){
         //    const validOrderOnDigitalStatus = await this.validOrderOnDigitalFn(publication, this.state.reader_mobile, this.state.reader_email);
         //    console.log(" validOrderOnDigitalStatus 333",validOrderOnDigitalStatus);

         //    if(!validOrderOnDigitalStatus){
         //       this.setState({loading: false});
         //       return;
         //    }
         // }
         if (Object.keys(selectedSchemeData).length > 0) {
            sessionStorage.setItem("selectedNewSchemes", JSON.stringify(selectedSchemeData));
            this.setState({ 
               homePage: false, 
               loading: false, 
               selectedOrder: false, 
               NewSchemePage: true, 
               showCororateOffersPopup: false, 
               showCheckoutModel: true, 
               showHomeDashbaord: false 
            });
            ReactGA.ga('send','pageview','/BuyNewPlan');
         }else{
            this.setState({
               loading: false,
               NewSchemePage: false, 
            });
            this.errorMsg("There is no new scheme found!");
         }
      } catch(err){
         console.log("showCurrentSchemes", err);
         this.setState({ loading: false });
         this.notFound();
      };
   }

   showNewSchemes() {
      const accountId = JSON.parse(sessionStorage.getItem('orderDetails')).id;
      this.setState({ loading: true });
      UserService.getExistingCustomerSchemes(accountId).then(response => {
         console.log("response ddsd = ", response);
         if (response.data.length !== 0) {
            if (response.data[0].publication_wise_schemes.length !== 0){
               // sessionStorage.setItem("selectedNewSchemes", JSON.stringify(response.data[0].publication_wise_schemes));
               this.setState({ 
                  loading: false,
                  showCororateOffersPopup: true, 
                  selectedCorporateSchemes: response.data[0].publication_wise_schemes,
                  forInstitutional: false
                });
               ReactGA.ga('send','pageview','/BuyNewPlan');
            }else{
               this.setState({ loading: false });
               this.errorMsg("There is no new scheme found!");
            }
         }else{
            this.setState({
               loading: false,
            });
            this.errorMsg("There is no new scheme found!");
         }
      }).catch(err => {
         console.log("showNewSchemes", err);
         this.setState({ loading: false });
         if(err?.data?.message){
            this.errorMsg(err?.data?.message);
         }else{
            this.notFound();
         }
      });
   }
   
   showCororateOffers() {
      this.setState({ loading: true });
      const accountId = JSON.parse(sessionStorage.getItem('orderDetails')).id;
      // this.setState({ showCororateOffersPopup: true });
      UserService.getExistingCustomerSchemes(accountId, true).then(response => {
         // console.log("response showCororateOffers = ", response);
         if (response.data.length !== 0) {
            if (response.data[0].publication_wise_schemes.length !== 0){
               // sessionStorage.setItem("selectedCorporateSchemes", JSON.stringify(response.data[0].publication_wise_schemes));
               this.setState({ 
                  loading: false,
                  showCororateOffersPopup: true,
                  selectedCorporateSchemes: response.data[0].publication_wise_schemes,
                  forInstitutional: true
                });
               ReactGA.ga('send','pageview','/Buy corporate plans');
            }else{
               this.setState({ loading: false });
               this.setState({ showCororateOffersPopup: false, selectedCorporateSchemes: {} });
               this.errorMsg("There is no new scheme found!");
            }
         }else{
            this.setState({
               loading: false,
               showCororateOffersPopup: false
            });
            this.errorMsg("There is no new scheme found!");
         }
      }).catch(err => {
         console.log("showCororateOffersPopup", err);
         this.setState({ loading: false, showCororateOffersPopup: false });
         if(err?.data?.message){
            this.errorMsg(err?.data?.message);
         }else{
            this.notFound();
         }
      });
   }

   currentScheme(e) 
   {
      sessionStorage.setItem("stg_mo_redirectFrom","Click on Renew Plan Button from HomePage");
      this.moengageRenewPlan_Clicked();
      let id = e.target.id;
      this.setState({ selectedOrder: true });
      let list = this.state.orders.map((obj) => {
         if (obj.id === id) {
            this.setState({ selectedOrder: true })

         }
         return obj;
      });
      this.setState({ orders: list });

      const salesOrg = e.target.id.split(" ")[0];
      const orderCode = e.target.id.split(" ")[1];
      const ordersData = e.target.id.split(" ")[2];
      const selectedPublication = e.target.id.split(" ")[3];
      const accountId = JSON.parse(sessionStorage.getItem('orderDetails')).id;
      sessionStorage.setItem("selectedPublication", selectedPublication);
      sessionStorage.setItem("orderId", ordersData);
      UserService.getAllowedSchemes(salesOrg, orderCode, accountId).then(response => {
         // console.log("response ddsd = ", response);
         if (response.data !== "") {
            if (response.data.schemes[0].comboSchemes.length !== 0 || response.data.schemes[0].currentSchemes.length !== 0){
               this.setState({ homePage: false });
               sessionStorage.setItem("selectedSchemes", JSON.stringify(response.data.schemes[0]));
               this.setState({ loading: false });
               this.setState({ selectedOrder: false })

               this.setState({ schemePage: true });
               this.setState({ showCheckoutModel: true });
               this.setState({ showHomeDashbaord: false });
               ReactGA.ga('send','pageview','/renewplan')
            }else{
               this.setState({ loading: false });
               this.errorMsg("There is no scheme map with this order");
            }
         }
      }).catch(err => {
         this.setState({ loading: false });
         this.notFound();
      })
   }

   redirectToHome() {

      window.location.reload();
   }

   handleLocationId = (locationId) => {
      if (locationId !== undefined) {
        sessionStorage.setItem("stg_location_code", locationId);
      }
   }

   render()
   {
      const { selectedDiv, openModalId, comlaintOrderId, orders, orderStages } = this.state;
      let isStageEmpty = false;
      const currentOrderStages = orderStages[openModalId];
      const selectedData = orders.find((order, key) => key === selectedDiv);
      let letter = 'A';
      printText = [];
      digitalText = [];
      if (this.state.isTimeOut) {
         sessionStorage.clear();
         alert("Session has been expired. Please login again");
         window.location.href = "/";
      }
      
      return (
         <section>
         {this.state.homePage &&
            <div id="home">
               {<LoggedInHeader />}
                  <div>
                  {this.state.pageLoader && 
                     <PageLoader/> }    
                {this.state.showHomeDashbaord &&
                  <div className="absolute-center">
                     <div className="container">
                        {/* old code add here */}
                        <div className="row mt-5-custom">
                           <div className="col-md-3 col-12">
                           <SideProfile
                              updateCorporateAccount={this.updateCorporateAccount}
                           />
                           </div>
                           <div className="col-md-9 col-12 all-orders">
                              <div className="col-md-12 order-title mb-3">
                                 <div className="order-back-btn">
                                    <span className="order-back-title">All Orders</span>
                                 </div>
                                 {!this.state.NewSchemePage && 
                                    <div className="order-select">
                                       <button onClick={this.showNewSchemes} className="pub-plan-btn renew-btn mr-2">Buy New Subscription</button>
                                    </div>
                                 }
                              </div>
                              {orders && orders.length > 0 ? (
                                 orders.map((orders, i) => 
                                 ( 
                                    
                                    <div 
                                    key={i}
                                    className="col-md-12 mb-3">
                                       {(() => {
                                          letter = String.fromCharCode(65 + i);
                                          isStageEmpty = orders.stage && Object.keys(orders.stage).length === 0;
                                          if(orders.couponPreference !== null)
                                          {
                                             if (orders.couponPreference.includes("M")) 
                                             {
                                                couponType="Digital Coupon";
                                             }
                                             else
                                             {
                                                couponType="Physical Coupon";
                                             }
                                          }
                                          else
                                          {
                                             couponType="";
                                          }
                                       })()}
                                       <div className={i % 2 === 0 ? "row pub-plan-sec redeem-back-one" : " row pub-plan-sec redeem-back-two"}>
                                          <div className="col-md-6 col-12">
                                             <div className="pub-plan-sub-sec pub-plan-sub-sec-font">
                                                <span className="publication">{publicationNameArr[orders.scheme.publications] ? publicationNameArr[orders.scheme.publications] : orders.scheme.publications}</span>
                                                <span className="separator"></span>
                                                <span className="plan">{Math.floor(orders.scheme.duration) || 'NA'} Months Plan</span>
                                                <div className="order-first-first">
                                                   <span className={`Dot ${orders.status ? orders.status + 'Dot' : 'greenDot'}`}></span>
                                                   <span className="last">{orders.status}</span>
                                                </div>
                                                <label className="pub-plan-label ml-2" onClick={() => this.handleDivClick(i, orders.id)}>View Detail</label>
                                             </div>
                                             <div className="pub-plan-sub-sec pub-plan-sub-sec-font-2">
                                             { (orders.couponNumber)  && (
                                                <>
                                                <span className="coupon-no d-flex align-items-center">
                                                   <span className="coupon-no-icon mr-2 d-flex">
                                                      <img src={couponIcon} width="18" />
                                                   </span>
                                                   <span className="coupon-no-text">{orders.couponNumber || 'N/A'}</span>
                                                </span>
                                                <span className="separator"></span>
                                                </>
                                             )}
                                                { (orders.order_start_date && orders.order_end_date) && (
                                                <span className="date-range d-flex align-items-center">
                                                   <span className="date-range-icon mr-2 d-flex">
                                                      <img src={eventScheduleIcon}></img>
                                                   </span>
                                                   <span className="date-range-text">{orders.order_start_date ? Moment(orders.order_start_date).format('DD.MM.YYYY') : 'NA'} - {orders.order_end_date ? Moment(orders.order_end_date).format('DD.MM.YYYY') : 'NA'}</span>
                                                   <span className="separator"></span>
                                                </span>
                                                )}
                                                <span className="date-range-text">{couponType}</span>
                                             </div>
                                          </div>
                                          <div className="col-md-6 col-12">
                                             <div className="pub-plan-btn-div">
                                                {(() => 
                                                {  
                                                   // console.log("ID = ", orders.id, "Draft = ", orders.status, "180 > ", orders.daysRemaining, "orderIdExistsInOpportunity", orders.orderIdExistsInOpportunity);                         
                                                   if (orders.status === "Draft" || orders.daysRemaining > 180 || orders.daysRemaining === null || orders.orderIdExistsInOpportunity) 
                                                   {
                                                      if(orders.status === "Draft" && orders.showRedeemCouponOption===true)
                                                      {
                                                         button = 
                                                            <button type="button" name={couponTYpe} id={orders.scheme.sapPublication} dataorderkey={i} dataorderid={orders.id} onClick={this.redeemCoupon} className="pub-plan-btn mr-2">Redeem Coupon</button>
                                                         
                                                      }
                                                      else if(orders.status === "Draft" )
                                                      {
                                                               button = <div></div>
                                                      }
                                                      else if (orders.showRedeemCouponOption===true)
                                                      {
                                                         var couponTYpe=orders.showRedeemCouponOption+orders.couponNumber+orders.id+"p2";
                                                         sessionStorage.setItem("stg_renew_clicked_order_status",orders.status);
                                                         sessionStorage.setItem("stg_renew_clicked_order_id",orders.id);
                                                         
                                                         button = 
                                                               <button type="button" name={couponTYpe} id={orders.scheme.sapPublication} dataorderkey={i} dataorderid={orders.id} onClick={this.redeemCoupon} className="pub-plan-btn mr-2">Redeem Coupon</button>
                                                      } 
                                                      else
                                                      {
                                                         button = <div></div>
                                                      }
                                                   } 
                                                   else 
                                                   {
                                                   if (orders.showRedeemCouponOption===true)
                                                   {
                                                      var couponTYpe=orders.showRedeemCouponOption+orders.couponNumber+orders.id+"p2";
                                                      sessionStorage.setItem("stg_renew_clicked_order_status",orders.status);
                                                      sessionStorage.setItem("stg_renew_clicked_order_id",orders.id);
                                                      
                                                      button = <>
                                                         <button type="button" name={couponTYpe} onClick={this.redeemCoupon} dataorderkey={i} dataorderid={orders.id} id={orders.scheme.sapPublication} className="pub-plan-btn mr-2">Redeem Coupon</button>
                                                         <button onClick={this.currentScheme} disabled={orders.selectedOrder} id={orders.scheme.salesOrg.id + " " + orders.scheme.code + " " + orders.id + " " + orders.scheme.publications} className="pub-plan-btn renew-btn mr-2">{orders.selectedOrder && <i className="fa fa-refresh fa-spin"></i>} Renew Plan</button>
                                                         </>
                                                   }
                                                   else
                                                   {
                                                      sessionStorage.setItem("stg_renew_clicked_order_status",orders.status);
                                                      sessionStorage.setItem("stg_renew_clicked_order_id",orders.id);
                                                   
                                                      button = 
                                                      <>
                                                         <button onClick={this.currentScheme} disabled={orders.selectedOrder} id={orders.scheme.salesOrg.id + " " + orders.scheme.code + " " + orders.id + " " + orders.scheme.publications} className="pub-plan-btn renew-btn mr-2">{orders.selectedOrder && <i className="fa fa-refresh fa-spin"></i>}Renew Plan</button>
                                                      </>
                                                   
                                                      // this.moengageRenewRedeem_Clicked();
                                                   }
                                                }
                                                })()
                                                }
                                                {button}
                                                {this.isWithin90Days(orders.order_end_date) && (
                                                   <button className="pub-plan-btn mr-2" onClick={() => this.showcontactUsPopUp(orders.id)}>Need Help?</button>
                                                )}
                                             </div>
                                          </div>
                                       </div>
                                       
                                       {/* defaultExpanded */}
                                       {/* <Accordion defaultExpanded={orders.showRedeemCouponOption} className="order-item">
                                          <AccordionSummary
                                             expandIcon={<div className="orderExpandIcon">
                                             <Typography variant="body1">
                                                {couponType}
                                             </Typography>
                                             <ExpandMoreIcon />
                                          </div>
                                       }
                                             aria-controls={`panel${i}-content`}
                                             id={`panel${i}-header`}
                                             className="">
                                             <div className="order-first-first">
                                                <span className="mr-3">Order {letter}</span>
                                                <span className={`Dot ${orders.status ? orders.status + 'Dot' : 'greenDot'}`}></span>
                                                <span className="last">{orders.status}</span>
                                             </div>
                                             <div className="order-first-second">
                                                <div className="order-publication-div">
                                                   <span className="fSec-head">Publication:</span>
                                                   <span className="fSec-title">{publicationNameArr[orders.scheme.publications] ? publicationNameArr[orders.scheme.publications] : orders.scheme.publications}</span>
                                                </div>
                                                <span className="separator"></span>
                                                <div className="order-plan-div">
                                                   <span className="fSec-head">Plan:</span>
                                                   <span className="fSec-title">{Math.floor(orders.scheme.duration) || 'NA'} Months</span>
                                                </div>
                                                { (orders.order_start_date && orders.order_end_date) && ( 
                                                   <>
                                                   <span className="separator"></span>
                                                   <div className="order-expiry-div">
                                                      <span className="fSec-head">Dates:</span>
                                                      <span className="fSec-title">{orders.order_start_date ? Moment(orders.order_start_date).format('DD.MM.YYYY') : 'NA'} - {orders.order_end_date ? Moment(orders.order_end_date).format('DD.MM.YYYY') : 'NA'}</span>
                                                   </div>
                                                   </>
                                                )}
                                             </div>
                                          </AccordionSummary>
                                          {!isStageEmpty &&
                                          <AccordionDetails>
                                             <div className="order-second">
                                             {Object.entries(orders.stage).map(([key, value]) => {
                                                let isActive = '';
                                                let title = '';
                                                let imgStatus = 'Inactive';

                                                if (typeof value === 'boolean' && value) {
                                                   isActive = 'active';
                                                   imgStatus = 'Active';
                                                }
                                                
                                                else if (Array.isArray(value) && value.length > 0 && ( value[0].status || value[0].status === 'Success' )) {
                                                   isActive = 'active';
                                                   imgStatus = 'Active';
                                                   if (key === 'paymentStatus') {
                                                      title = `status: ${value[0].status} | transactionId: ${value[0].transactionId} | mode: ${value[0].mode}`;
                                                   }
                                                   else if (key == 'couponDispatch') {
                                                      title = `status: ${value[0].status} | dispatchDate: ${value[0].dispatchDate} | awbNo: ${value[0].awbNo}`;
                                                   }
                                                } else if (typeof value === 'object' && value !== null && (value.status || value.status === 'Success')) {
                                                   isActive = 'active';
                                                   imgStatus = 'Active';
                                                   if (key === 'paymentStatus') {
                                                      title = `status: ${value.status} | transactionId: ${value.transactionId} | mode: ${value.mode}`;
                                                   }
                                                   else if (key == 'couponDispatch') {
                                                      title = `status: ${value.status} | dispatchDate: ${value.dispatchDate} | awbNo: ${value.awbNo}`;
                                                   }
                                                }
                                                return (
                                                <div key={key} className={`order-second-first ${isActive}`} data-id={key+" - "+value}>
                                                   <CustomTooltip 
                                                      title={title}
                                                      placement="top" 
                                                      arrow 
                                                      enterDelay={500} 
                                                      leaveDelay={200}
                                                   >
                                                      <img className="img" src={stageInfo[key].imgSrc[imgStatus]} alt={stageInfo[key].imgSrc[imgStatus]}  />
                                                   </CustomTooltip>
                                                   <span className="text">{stageInfo[key].text}</span>
                                                </div>
                                                );
                                             })}
                                                
                                             </div>
                                          </AccordionDetails>
                                          } 
                                          <AccordionActions>
                                             {(() => 
                                             {                         
                                                if (orders.status === "Draft" || orders.daysRemaining > 180 || orders.daysRemaining === null || orders.orderIdExistsInOpportunity) 
                                                {
                                                   if(orders.status === "Draft" && orders.showRedeemCouponOption===true)
                                                   {
                                                      button = 
                                                      <div className="order-first-third">
                                                         <button type="button" name={couponTYpe} id={orders.scheme.sapPublication} dataorderid={orders.id} onClick={this.redeemCoupon} className="btn">Redeem Coupon</button>
                                                      </div>
                                                   }
                                                   else if(orders.status === "Draft" )
                                                   {
                                                            button = <div></div>
                                                   }
                                                   else if (orders.showRedeemCouponOption===true)
                                                   {
                                                      var couponTYpe=orders.showRedeemCouponOption+orders.couponNumber+orders.id+"p2";
                                                      sessionStorage.setItem("stg_renew_clicked_order_status",orders.status);
                                                      sessionStorage.setItem("stg_renew_clicked_order_id",orders.id);
                                                      
                                                      button = 
                                                         <div className="order-first-third">
                                                            <button type="button" name={couponTYpe} id={orders.scheme.sapPublication} dataorderid={orders.id} onClick={this.redeemCoupon} className="btn">Redeem Coupon</button>
                                                         </div>
                                                   } 
                                                   else
                                                   {
                                                      button = <div></div>
                                                   }
                                                } 
                                                else 
                                                {
                                                if (orders.showRedeemCouponOption===true)
                                                {
                                                   var couponTYpe=orders.showRedeemCouponOption+orders.couponNumber+orders.id+"p2";
                                                   sessionStorage.setItem("stg_renew_clicked_order_status",orders.status);
                                                   sessionStorage.setItem("stg_renew_clicked_order_id",orders.id);
                                                   
                                                   button = 
                                                   <div className="order-first-third">
                                                      <button type="button" name={couponTYpe} onClick={this.redeemCoupon} dataorderid={orders.id} id={orders.scheme.sapPublication} className="btn mr-4">Redeem Coupon</button>
                                                      <button onClick={this.currentScheme} disabled={orders.selectedOrder} id={orders.scheme.salesOrg.id + " " + orders.scheme.code + " " + orders.id + " " + orders.scheme.publications} className="btn">{orders.selectedOrder && <i className="fa fa-refresh fa-spin"></i>} Renew Plan</button>
                                                   </div>
                                                }
                                                else
                                                {
                                                   sessionStorage.setItem("stg_renew_clicked_order_status",orders.status);
                                                   sessionStorage.setItem("stg_renew_clicked_order_id",orders.id);
                                                
                                                   button = 
                                                   <div className="order-first-third">
                                                      <button onClick={this.currentScheme} disabled={orders.selectedOrder} id={orders.scheme.salesOrg.id + " " + orders.scheme.code + " " + orders.id + " " + orders.scheme.publications} className="btn">{orders.selectedOrder && <i className="fa fa-refresh fa-spin"></i>}Renew Plan</button>
                                                   </div>
                                                
                                                   // this.moengageRenewRedeem_Clicked();
                                                }
                                             }
                                             })()
                                             }
                                             {button}
                                          </AccordionActions>
                                       </Accordion> */}
                                    </div>
                                 ))
                              ) : (
                                 <div className="col-md-12">
                                  <div className="CouponNotFound mt-2 mb-4">
                                    <img src={NoDataFoundImg}></img>
                                    {/* <p className="text-dark">
                                      To view your m-coupons please click visit the 
                                      <a className="order-alink" href='/'>All Orders</a> section
                                    </p> */}
                                    <h3>No Orders Found!</h3>
                                  </div>
                                </div>
                              )}
                           </div>
                        </div>
                        
                     </div>
                     {/* <ToastContainer /> */}
                  </div>
                  }
            </div>   
            {this.state.CorporateSchemeFlag && 
               <div className="container">
                  <div className="row justify-content-end">
                    <div className="bottom_complaint">
                        <button className="btn" data-toggle="modal" data-target="#contact_box" onClick={this.showCororateOffers}>
                          <img className="mr-2" src={clsoedIcon}></img>
                          <span>CORPORATE OFFERS</span>
                        </button>
                    </div>
                  </div>
              </div>        
            }                  
         </div>
         }
         <CorporateOffers
            showModalPopup={this.state.showCororateOffersPopup}
            onPopupClose={this.closeCororateOffers}
            errorMsg={this.errorMsg}
            selectedCorporateSchemes={this.state.selectedCorporateSchemes}
            forInstitutional={this.state.forInstitutional}
            showCurrentSchemes={this.showCurrentSchemes}
         >
         </CorporateOffers>
         {this.state.schemePage && <AllSchemes style={{ "height": "100%" }} schemeCallBack={this.goBackPage} ></AllSchemes>
         }
         {this.state.NewSchemePage && <NewSchemes style={{ "height": "100%" }} showErrors={this.errorMsg} schemeCallBack={this.goBackPage} forInstitutional={this.state.forInstitutional} ></NewSchemes>
         }
         {this.state.changeVendorDetailsShow && <VendorDetailsChange CouponDetailConfirm={this.gotoHomePage} vendorBack={true}/>
         }
         {
            !this.state.schemePage &&
               <PopupComplaint
                  showModalPopup={this.state.showPopupAlertState}
                  onPopupClose={this.closecontactUsPopUp}
                  orderID={comlaintOrderId}
               >
               </PopupComplaint>
         }

         { openModalId !== null && (
            <OrderDetails
               isOpenDetail={openModalId !== null}
               onCloseDetail={this.closeOrderDetailsBox}
               orderDetail={orders.find(order => order.id === openModalId)}
               orderStages={currentOrderStages}
               orderKey={selectedDiv}
            />
         )}
         { this.state.validDigitalOrder && (
            <CorporateDigitalPopup show={this.state.validDigitalOrder}
              handleClose={(success) => {
                  this.setState({validDigitalOrder: false});
                  // setOtpValidated(success);
              }}
              mobile={this.state.reader_mobile}
              email={this.state.reader_email}
            />
         )}
      </section>
      );
   }

}