import React, { useState, useEffect } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/poppins';
import './../css/Style.css';
import subscription from '../images/subscription.png';
import { Modal, Button, Form } from 'react-bootstrap';

function CorporateDigitalPopup({ show, handleClose, mobile, email }) {
    const [error, setError] = useState('');

    useEffect(() => {
        console.log("popupType = ", show);
    });

    const handleCloseOtp = async (status) => {
        handleClose(status);
    }
    const handleCloseOtpDigital = async (status) => {
        handleClose(status);
        window.location = `/`;
    }
    
    return (
        
                <Modal show={show} onHide={() => handleCloseOtp(false)} centered>
                <Modal.Header className="bg-corporate" closeButton>
                    <div className="bg-corporate d-flex justify-content-center pb-3">
                        <img className="img-widht-12" src={subscription} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flax text-center text-dark" role="alert">
                        
                        <h4 className="alert-heading corporate-title">
                            You Already Have a Digital Subscription!
                        </h4>
                        <p>
                        Hey! You’re already subscribed to <b>Live Mint</b>. No need to buy again—explore upgrades or other plans instead!
                            {' '}
                        </p>
                        <span className="phone-number font-weight-bold">{email} OR {mobile}</span>
                        {/* <hr />
                        <p className="mb-0">If you need assistance or wish to manage your subscription. </p>
                        <ul className="nav col-12 justify-content-end list-unstyled d-flex bg-dark p-3"><li className="ms-3"><a className="text-white" href="tel:+022-67764242">
                            <img src="/static/media/footer-phone.829c3432c0c3bb55c3fdb8c3c5350baf.svg" width="12" height="12" /> Mumbai: 022-67764242</a></li><li className="ms-3 ml-1"><a className="text-white" href="tel:+011-60004242">| Delhi: 011-60004242</a></li><li className="ms-3 mr-3 ml-1"><a className="text-white" href="tel:7009989177">| Punjab: +91 7009989177</a></li><li className="ms-3"><a className="text-white" href="mailto:customerservice@hindustantimes.com">
                            <img src="/static/media/footer-mail.cca4e5dbd526d12d2f17224ff1b41638.svg" width="12" height="12" className="mr-1" />customerservice@hindustantimes.com</a></li></ul> */}
                    </div>
                    <div className="corporate-otp-btn justify-content-center">
                        <Button variant="primary" onClick={handleCloseOtpDigital}>
                        Go To Home
                        </Button>
                    </div>
                </Modal.Body>
                </Modal>
            
      );
      
}

export default CorporateDigitalPopup;

