import moment from "moment";
import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY; // Store securely (e.g., in .env file)


/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYYY HH:MM");
  return buildDate;
};

export const planNames = {
  "HT": "Hindustan Times Newspaper",
  "HH": "Hindustan Newspaper",
  "BP": "Mint Newspaper",
  "Mint": "Mint Newspaper",
  "LM": "Live Mint Subscription",
  "LH": "Live Hindustan Subscription",
  "HT;HH": "Hindustan Times and Hindustan Newspaper",
  "HT;Mint": "Hindustan Times and Mint Newspaper",
  "Mint;LM": "Mint Newspaper and Live Mint Subscription",
  "LM;Mint": "Mint Newspaper and Live Mint Subscription",
  "BP;LM": "Mint Newspaper and Live Mint Subscription",
};

// Encrypt data before storing
const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

// Decrypt data when retrieving
const decryptData = (ciphertext) => {
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
};

// Save Encrypted Data to sessionStorage
const saveToSessionStorage = (key, value) => {
  sessionStorage.setItem(key, encryptData(value));
};

// Retrieve & Decrypt Data from sessionStorage
const getFromSessionStorage = (key) => {
  const encryptedData = sessionStorage.getItem(key);
  return encryptedData ? decryptData(encryptedData) : null;
};

// Remove Item from sessionStorage
const removeFromSessionStorage = (key) => {
  sessionStorage.removeItem(key);
};
