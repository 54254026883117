import React, {useState, useEffect} from 'react';

import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/poppins';
import './../css/Style.css';
import './../css/complaint-management.css';
import MintLogo from "../images/Mint_Corporate_logo.png";
import { Modal } from 'react-bootstrap';
import PageLoader from '../subComponents/PageLoader';
import UserService from "../services/UserService";
import hindustan_times_mint from "../images/Acquisition/hindustan_times_mint.png";
import hindustan_times_hh from "../images/Acquisition/hindustan_times_hh.png";
import hindustan_times from "../images/Acquisition/hindustan_times.svg";
import hindustan from "../images/Acquisition/hundustan.png";
import livemint from "../images/Acquisition/livemint.svg";
import onlylivemint from "../images/onlylivemint.png";
import mint_livemint from "../images/mint-livemint.png";
import {planNames} from '../utils/utils.js';


const publicationImg = { 'HT': hindustan_times, 'Mint': livemint, 'BP': livemint, 
  'HT;Mint': hindustan_times_mint, 'HT;HH': hindustan_times_hh, 'HH': hindustan, 
  'Mint;LM': mint_livemint, 'LM;Mint': mint_livemint, 'LM': onlylivemint };

function CorporateOffers({ showModalPopup, onPopupClose, errorMsg, selectedCorporateSchemes={}, forInstitutional, showCurrentSchemes }) {

    const [loading, setLoading] = useState(false);
    const [selectedSchemes, setSelectedSchemes] = useState([]);
    
    // useEffect(() => {
    //     try {
    //         // setData({email: , mobile_no: sessionStorage.getItem('orderDetails')});
    //         console.log("dsasad = ", selectedCorporateSchemes);
    //         // Ensure selectedCorporateSchemes is an object
    //         let schemesData = selectedCorporateSchemes || {};
    //         // schemesData = await filterSchemes(schemesData);
    //         // console.log("schemesData == ", schemesData);
    //         // Combine schemes from all publications (Mint, HT, etc.)
    //         // const combinedSchemes = Object.entries(schemesData).flatMap(([publication, schemesArray]) => {
    //         //     // Check if schemesArray is indeed an array, then return it
    //         //     return Array.isArray(schemesArray) ? schemesArray.map(scheme => ({ ...scheme, publications: publication })) : [];
    //         // });

    //         const combinedSchemes = filterSchemes(schemesData);
    //         // console.log("schemesData == ", combinedSchemes);

    //         setSelectedSchemes(combinedSchemes || []);  // Default to empty array if no schemes found
    //         console.log("selectedSchemes == ", combinedSchemes);
    //     } catch (error) {
    //         console.error("Error parsing stored data:", error);
    //     }
    // }, [selectedCorporateSchemes]);

    useEffect(() => {
        const processSchemes = async () => {
            try {
                // console.log("dsasad = ", selectedCorporateSchemes);
                let schemesData = selectedCorporateSchemes || {};
                
                // Await the resolved value from the async function
                const combinedSchemes = await filterSchemes(schemesData);
    
                setSelectedSchemes(combinedSchemes || []);  // Default to empty array if no schemes found
                console.log("selectedSchemes == ", combinedSchemes);
            } catch (error) {
                console.error("Error parsing stored data:", error);
            }
        };
    
        processSchemes();
    }, [selectedCorporateSchemes]);
    
    const notify = (msg) => {
        errorMsg(msg);
    };

    const handleModalClose = () => {
        // console.log("Modal closed.");
        // Add any logic you want to perform on modal close
        setSelectedSchemes({});
        onPopupClose(); // Call the parent-provided close function
    };

    const showCurrentSchemesPage = async(code, publication=null) => {
        // console.log("go to checkout page.", code);
        // Add any logic you want to perform on modal close
        let publication_wise_schemes = selectedSchemes.find(scheme => scheme.code === code);
        // setSelectedSchemes(filteredSchemes);
        const selected = selectedSchemes.filter(scheme => scheme.code === code);

        if (selected.length > 0) {
            // Dynamically structure the selected scheme into the desired format
            publication_wise_schemes = selected.reduce((acc, scheme) => {
                // Check if the publication group already exists, if not create it
                if (!acc[scheme.publications]) {
                    acc[scheme.publications] = [];
                }
                acc[scheme.publications].push(scheme);
                return acc;
            }, {});
        }

        // console.log("selectedSchemeseeeeee == ", publication_wise_schemes);
        setSelectedSchemes({});
        showCurrentSchemes(publication_wise_schemes, publication); // Call the parent-provided close function
        onPopupClose();
    };

    const filterSchemes = async (data) => {
        const result = [];
      
        for (const schemes of Object.values(data)) {
          // Group schemes by year
          const groupedByYear = schemes.reduce((acc, scheme) => {
            const { duration, price } = scheme;
            acc[duration] = acc[duration] || [];
            acc[duration].push(scheme);
            return acc;
          }, {});
      
          // Process each group by year
          for (const schemes of Object.values(groupedByYear)) {
            if (schemes.length === 1) {
              // If only one scheme for the year, add it directly
              result.push(schemes[0]);
            } else {
              // If multiple schemes for the year, add the one with the lesser price
              const leastPriceScheme = schemes.reduce((min, scheme) =>
                scheme.price < min.price ? scheme : min
              );
              result.push(leastPriceScheme);
            }
          }
        }
      
        return result; // Return a flat array
      };

    if (!showModalPopup) return null;

    return (
        <>
            {loading && <PageLoader />}
            { forInstitutional === true ? (
                <Modal
                    show={showModalPopup}
                    onHide={handleModalClose}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="CI-details"
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="CITitle">Corporate Offers</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: '0px' }}>
                        <div className="corporate-offers" style={{ color: 'black' }}>
                            {/* <p className="corporate-p ht-info-badge">
                                You already have an active Mint Print subscription. Please choose from the following Combo or Digital-only schemes.
                            </p> */}
                            <p className="corporate-p ht-info-badge">
                                Please choose from the following Combo or Digital-only schemes.
                            </p>
                            {selectedSchemes.length > 0 ? (
                                <div className="offer-main">
                                    {selectedSchemes.map((scheme, index) => (
                                        <div className="offer-card" key={scheme.id}>
                                            <div className="logo">
                                                <img
                                                    src={publicationImg[scheme.publications]}
                                                    className="img-fluid"
                                                    alt={`${scheme.publications} logo`} 
                                                />
                                            </div>
                                            <div className="cost">
                                                <span className="price">₹ {scheme.price}</span>
                                                <span className="time">/{scheme.duration} Month</span>
                                            </div>
                                            <button 
                                                onClick={() => showCurrentSchemesPage(scheme.code, scheme.publications)} 
                                                className="btn">
                                                {planNames[scheme.publications] || scheme.publications}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p>No schemes available.</p>
                            )}
                        </div>
                    </Modal.Body>
                </Modal>
            ) : (
                <Modal
                    show={showModalPopup}
                    onHide={handleModalClose}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="CI-details"
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="CITitle">New Subscribcriptions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: '0px' }}>
                        
                        <div className="corporate-offers" style={{ color: 'black' }}>
                            <p className="corporate-p ht-info-badge">
                                Please choose from the following print schemes.
                            </p>
                            {selectedSchemes.length > 0 ? (
                                <div className="offer-main">
                                    {selectedSchemes.map((scheme, index) => (
                                        <div className="offer-card" key={scheme.id}>
                                            <div className="logo">
                                                <img
                                                    src={publicationImg[scheme.publications]}
                                                    className="img-fluid"
                                                    alt={`${scheme.publications} logo`} 
                                                />
                                            </div>
                                            <div className="cost">
                                                <span className="price">₹ {scheme.price}</span>
                                                <span className="time">/{scheme.duration} Month</span>
                                            </div>
                                            <button 
                                                onClick={() => showCurrentSchemesPage(scheme.code)} 
                                                className="btn">
                                                {planNames[scheme.publications] || scheme.publications}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p>No schemes available.</p>
                            )}
                        </div>
                    </Modal.Body>
                </Modal>
            )}
            
        </>
    );
}


export default CorporateOffers;
