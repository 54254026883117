import React, { useState, useEffect, useRef } from 'react';
import {motion} from 'framer-motion'
import './../css/Acquisition.css';
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import manProfileImg from "../images/Orders/man.png";
import editProfileIcon from "../images/Profile-Menu/edit_profile.svg";
import changeVendorIcon from "../images/Profile-Menu/change_vendor.svg";
import orderListIcon from "../images/Profile-Menu/order_list.svg";
import couponsIcon from "../images/Profile-Menu/coupons.svg";
import complaintsIcon from "../images/Profile-Menu/complaints.svg";
import relationshipIcon from "../images/Profile-Menu/relationship.svg";
import logoutIcon from "../images/Profile-Menu/logout.svg";
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import VendorDetailsChange from '../components/VendorDetailsChange';
import PopupCIDetails from './PopupCIDetails';
import ProfileIcon from '../components/ProfileIcon';
import InstallButton from '../components/InstallButton';

  
const NavLinks=(props)=>
{

  const [isOpen, setIsOpen] = useState(false);
  const [showPopupCIDetails, setShowPopupCIDetails] = useState(false);
  const dropdownRef = useRef(null);
  const animateFrom ={opacity:0, y: -40}
  const animateTo ={opacity:1, y: 0}

  const userInfo = JSON.parse(sessionStorage.getItem('orderDetails')) || {
    customerName: '',
    mobile: '',
    address: '',
    email: '',
  };

    const VendorDetailsChangeRef = React.createRef();

    const handleShow = () => {
      if (VendorDetailsChangeRef.current) {
        VendorDetailsChangeRef.current.handleShow();
      }
    };

    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  
    const closeDropdown = () => {
      setIsOpen(false);
    };
  
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  
    const history = useHistory();

    function redirectToHome() 
    {
         window.location.href = '/';
    }

    function moengageLogoutBtn_Clicked()
    {
      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {
        window.Moengage.track_event( "Logout Button Clicked", 
         {
            Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
            Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),Registered: sessionStorage.getItem("stg_acq_userType")
         }
        )
      }
      window.Moengage.destroy_session();
    }
  
    function logout() 
    {
    //  debugger
    moengageLogoutBtn_Clicked();
    window.location.href = '/';
    sessionStorage.removeItem('orderDetails');
    sessionStorage.removeItem('selectedSchemes');
    sessionStorage.removeItem('selectedCouponsDue');
    sessionStorage.removeItem('journey');
    localStorage.removeItem('AuthInfo');
    sessionStorage.removeItem('stg_utm_source');
    sessionStorage.removeItem('stg_utm_medium');
    sessionStorage.removeItem('stg_utm_name');
    sessionStorage.removeItem('stg_mobile_number');
    sessionStorage.removeItem('stg_status');
    sessionStorage.removeItem('stg_clicked_OrderId');
    sessionStorage.removeItem('stg_renew_clicked_order_status');
    sessionStorage.removeItem('stg_renew_clicked_order_id');
    sessionStorage.removeItem('stg_selectedPlan_Name');
    sessionStorage.removeItem('stg_selectedPlan_Duration');
    sessionStorage.removeItem('stg_selectedPlan_Price');
    sessionStorage.removeItem('stg_renew_clicked_order_id');
    sessionStorage.removeItem('stg_emailAdress_Checkout_updated');
    sessionStorage.removeItem('stg_existing_address_DeliverPage_updated');
    sessionStorage.removeItem('stg_new_address_DeliverPage_updated');
    sessionStorage.removeItem('stg_mo_redirectFrom');
    sessionStorage.removeItem("stg_mo_OTP_Status");
    sessionStorage.removeItem("stg_acq_exitingUser")
    sessionStorage.removeItem("stg_acq_freshUser")
    sessionStorage.removeItem("acqPhoneNumber")
    sessionStorage.removeItem("createNewAccountActive")
    sessionStorage.removeItem("mo_acq_numberStatus")
    sessionStorage.removeItem("stg_acq_otp")
    sessionStorage.removeItem("stg_acq_userType")
    sessionStorage.clear();
    // Clear cookies
    document.cookie.split(";").forEach(function(c) {
    document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    }

    const showComplaints = () => {
      // setShowPopupComplaintsState(true);
      window.location.href = "/query-management";  
    };
    const showCIDetails = () => {
      setShowPopupCIDetails(true);
    };
    const closeCIDetailsBox = () => {
      setShowPopupCIDetails(false);
    };

    function redeem()
    {
      window.location.href = "/redeem";   
    }

    //  function vendorChange()
    //  {
    //     window.location.href = "/vendorChange";   
    //  }

  return (
    <div className="row align-items-center">
      <div className="col-8 d-none">
          <form className="app-search d-none d-md-block me-auto">
              <div className="position-relative">
                  <input type="search" className="form-control" placeholder="Search..." autoComplete="off" value="" />
                  <iconify-icon icon="solar:magnifer-broken" className="search-widget-icon"></iconify-icon>
              </div>
          </form>
      </div>
      <div className="col-1 mr-2">
          <a className="link-dark" href='/faq'>FAQ</a>
      </div>
      <div className="col-3">
        <div className="dropdown topbar-item d-flex" ref={dropdownRef}>
          <span 
            type="button"
            className="topbar-button"
            id="page-header-user-dropdown"
            aria-haspopup="true"
            aria-expanded={isOpen}
            style={{ color: "black" }}
            onClick={toggleDropdown}
          >
            <span className="d-flex align-items-center">
              {/* <img className="rounded-circle img-avatar" width="32" src={manProfileImg} alt="avatar-3" /> */}
              <ProfileIcon name={userInfo.customerName} />
              { !props.isMobile ? (
                <span className="ml-2 limited-text">{userInfo.customerName || 'NA'}</span>
              ) : null }
              <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
            </span>
          </span>
          <div className={`dropdown-menu dropdown-menu-end ${isOpen ? 'show' : ''}`} data-popper-placement="bottom-end" style={{ position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate3d(174px, 50px, 0px)" }}>

            { props.isMobile ? (
              <h6 className="dropdown-header">Welcome {userInfo.customerName || 'NA'}!</h6>
            ) : null }
            <motion.div
              className="dropdown-item"
              initial={animateFrom}
              animate={animateTo}
              transition={{ delay: 0.05 }}
              onClick={() => props.isMobile && props.cloaseMobile()}>
              <a onClick={redirectToHome}><img className="mr-2" src={editProfileIcon} /><span>Edit Profile</span></a>
            </motion.div> 
            <motion.div
              className="dropdown-item"
              initial={animateFrom}
              animate={animateTo}
              transition={{ delay: 0.05 }}>
              <a onClick={handleShow}><img className="mr-2" src={changeVendorIcon} /><span>Change Vendor</span></a>
            </motion.div>
            <motion.div
              className="dropdown-item"
              initial={animateFrom}
              animate={animateTo}
              transition={{ delay: 0.05 }}
              onClick={() => props.isMobile && props.cloaseMobile()}>
              <a onClick={redirectToHome}><img className="mr-2" src={orderListIcon} /><span>Order List</span></a>
            </motion.div>
            <motion.div
              className="dropdown-item"
              initial={animateFrom}
              animate={animateTo}
              transition={{ delay: 0.05 }}
              onClick={() => props.isMobile && props.cloaseMobile()}>
              <a onClick={redeem}><img className="mr-2" src={couponsIcon} /><span>My Coupons</span></a>
            </motion.div>
            <motion.div
              className="dropdown-item"
              initial={animateFrom}
              animate={animateTo}
              transition={{ delay: 0.05 }}
              onClick={() => props.isMobile && props.cloaseMobile()}>
              <a onClick={showComplaints}><img className="mr-2" src={complaintsIcon} /><span>Help & Support</span></a>
            </motion.div>

            <motion.div
              className="dropdown-item"
              initial={animateFrom}
              animate={animateTo}
              transition={{ delay: 0.05 }}>
              <a onClick={showCIDetails}><img className="mr-2" src={complaintsIcon} /><span>My Relationship Manager</span></a>
            </motion.div>
            {/* <motion.div
              className="dropdown-item"
              initial={animateFrom}
              animate={animateTo}
              transition={{ delay: 0.05 }}>
                <InstallButton />
            </motion.div> 

            <motion.div
              initial={animateFrom}
              animate={animateTo}
              transition={{delay:0.05}}
              onClick={() => props.isMobile && props.cloaseMobile()}>
                <a>Profile</a>
            </motion.div>

            <motion.div
              initial={animateFrom}
              animate={animateTo}
              transition={{delay:0.05}}
              onClick={() => props.isMobile && props.cloaseMobile()}>
                <a>Contact Us</a>
            </motion.div> */}
            <div className="dropdown-divider my-1"></div>
            <motion.div
              className="dropdown-item"
              initial={animateFrom}
              animate={animateTo}
              transition={{ delay: 0.05 }}
              onClick={() => props.isMobile && props.cloaseMobile()}>
              <a onClick={logout}><img className="mr-2" src={logoutIcon} /> <span>Logout</span></a>
            </motion.div>
          </div>
          <ToastContainer />

          <VendorDetailsChange ref={VendorDetailsChangeRef} vendorBack={false} />
           
          <PopupCIDetails
              isOpenCI={showPopupCIDetails}
              onCloseCI={closeCIDetailsBox}>
          </PopupCIDetails>
          
        </div>    
      </div>
    </div>        
  )
}
  
export default NavLinks;