import React, { useState, useEffect } from 'react';
import LoggedInHeader from '../subComponents/LoggedInHeader';
import './../css/Checkout.css';
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import '@fontsource/poppins';
import 'bootstrap/dist/css/bootstrap.min.css';
import {planNames} from '../utils/utils.js';
import { schemeDescription, duplicateImageFilter } from '../services/schemeTextFilter';
// import { ToastContainer, toast } from 'react-toastify';
import ShowPaytmPaymentPage from './ShowPaytmPaymentPage';
import ShowRazorPayPaymentPage from './ShowRazorPayPaymentPage';
import PaymentOffer from '../subComponents/PaymentOffer';
import PaymentSummary from '../subComponents/PaymentSummary';
import AcqHeader from "../subComponents/AcqHeader";
import PopupAlert from '../subComponents/PopupAlert';
import PopupRemoveCouponCode from '../subComponents/PopupRemoveCouponCode';
import PageLoader from '../subComponents/PageLoader';
import UserService from '../services/UserService';
import validator from 'validator';
import ReactGA from 'react-ga';


let duplicateImageRemoved;
var printText = [];
var digitalText = [];
let compoPlan =[];

const NewSchemes = ({ schemeCallBack, showErrors, forInstitutional=false }) => {
    const [state, setState] = useState({
        selectedSchemes: JSON.parse(sessionStorage.getItem('selectedNewSchemes')) || [],
        schemePage: true,
        checkout: false,
        loading: false,
        payforScheme: [],
        comboSchemesShow: false,
        currentPlanPublication: '',
        currentPlanDuration: '',
        currentPlanPrice: '',
        paymentOption: {
            gateway: sessionStorage.getItem('default_gateway'),
            offerCode: null,
            offerDescription: null,
        },
        paymentOptionGateway: sessionStorage.getItem('default_gateway'),
        paymentOptionOfferCode: null,
        paymentOptions: [],
        randomUUId: '',
        transToken: '',
        paytmPageShow: false,
        updateEmail: '',
        mobile: '',
        selectedSchemesInfo: '',
        showPaymentOffer: false,
        planNameOnSummary: '',
        showTnC: false,
        isCheckedTnc: true,
        showPopupAlertState: false,
        tncValueStatus: '',
        isEmailAdressValid: '',
        couponNotAdded: true,
        couponCodeValue: '',
        couponDiscountAmount: '',
        actualPlanPrice: '',
        removeCouponCodePopup: false,
        showPopupAlertStateRemoveCoupon: false,
        triggerSelectPlan: false,
        utmSource: null,
        currentPlanId: '',
        accountId: '',
        leadId: null,
    });

    
    const click = () =>
    {
        sessionStorage.setItem("stg_mo_redirectFrom","Click on Back Link From Checkout Page")
        schemeCallBack();
    }

    const notify = (msg) => {
    //   toast.warn(msg, { position: toast.POSITION.TOP_CENTER });
        showErrors(msg);
    };

  const handleStateChange = (field, value) => {
      setState((prev) => ({ ...prev, [field]: value }));
  };

    useEffect(() => {
        const initialize = () => {
            const loggedIn = sessionStorage.getItem("stg_loggedInHeader") === "ON";
            const orderDetails = JSON.parse(sessionStorage.getItem('orderDetails') || '{}');
                // console.log("orderDetails ============== ", orderDetails);
            if (loggedIn && orderDetails && orderDetails.id) {
                handleStateChange('updateEmail', orderDetails.email);
                handleStateChange('mobile', orderDetails.mobile);
                handleStateChange('selectedSchemesInfo', orderDetails);
                handleStateChange('accountId', orderDetails.id);
            } else {
                handleStateChange('mobile', sessionStorage.getItem("acqPhoneNumber"));
                sessionStorage.setItem("orderId", "fresh_" + sessionStorage.getItem("acqPhoneNumber"));
                handleStateChange(
                    'updateEmail',
                    sessionStorage.getItem("stg_emailAdress_Checkout_updated") || ''
                );
            }

            generateRandomUUId();
            updateSelectedPlanInfo();
        };

        const generateRandomUUId = () => {
            const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
            const length = 15;
            const randomUUId = Array.from({ length }, () =>
                chars[Math.floor(Math.random() * chars.length)]
            ).join('');
            handleStateChange('randomUUId', randomUUId);
            sessionStorage.setItem("linkId", randomUUId);
        };

        const updateSelectedPlanInfo = () => {
            const selectedSchemes = state.selectedSchemes;
            const firstKey = Object.keys(selectedSchemes)[0]; // Dynamically get the first key
            const firstArray = selectedSchemes[firstKey];

            if (firstArray.length > 0) {
                const firstScheme = firstArray[0];
                updatePlanDetails(firstScheme);
            }
        };

        const updatePlanDetails = (plan) => {
            handleStateChange('currentPlanPublication', plan.publications);
            handleStateChange('currentPlanDuration', plan.duration);
            handleStateChange('currentPlanPrice', plan.price);
            handleStateChange('actualPlanPrice', plan.price);
            handleStateChange('currentPlanId', plan.id);
            handleStateChange('tncValueStatus', plan.tncLink);
            sessionStorage.setItem("stg_selectedPlan_Name", plan.publications);
            sessionStorage.setItem("stg_selectedPlan_Duration", plan.duration);
            sessionStorage.setItem("stg_selectedPlan_Price", plan.price);
            getPlanNameOnSummary(plan.publications);
        };

        initialize();
    }, []);

    useEffect(() => {
        if (state.leadId) {
            paymentDoneSuccess();
        }
    }, [state.leadId]);

  const onDismissRazorpay = () => {
      handleStateChange('paytmPageShow', false);
      handleStateChange('loading', false);
  };

  const selectPaymentOfferL1 = (value) => {
      handleStateChange('paymentOption', { gateway: value[0], offerCode: value[1] });
      handleStateChange('paytmPageShow', false);
      sessionStorage.setItem("stg_Payment_Offer_Selected", `${value[0]}&${value[1]}`);
  };

  const showTncModel = (status) => {
      sessionStorage.setItem("stg_mo_redirectFrom", "Click on T&C Link From Checkout Page");
      handleStateChange('showTnC', status);
  };

  const showPopUpAlert = (status) => {
      sessionStorage.setItem("stg_mo_redirectFrom", "Open Alert on Clicking Checkout Without selecting T&C Checkbox");
      handleStateChange('showPopupAlertState', status);
  };

  const showPopUpAlertRemoveCoupon = (status) => {
      handleStateChange('showPopupAlertStateRemoveCoupon', status);
  };

    const handleTncChange = () => {
        handleStateChange('isCheckedTnc', !state.isCheckedTnc);
    };

    const redirectToHome = () => {
        if (sessionStorage.getItem("createNewAccountActive") === "ON") {
            // Perform cleanup for new account navigation
            sessionStorage.removeItem("orderDetails");
            sessionStorage.removeItem("acqPhoneNumber");
            sessionStorage.removeItem("stg_acq_exitingUser");
            sessionStorage.removeItem("stg_acq_freshUser");

            sessionStorage.removeItem("moe_acq_selected_yearPlan");
            sessionStorage.removeItem("moe_acq_selected_planPrice");
            sessionStorage.removeItem("moe_acq_Pincode");
            sessionStorage.removeItem("stg_nda_adressDetails");
            sessionStorage.removeItem("acqAccountNotAvailable_via_Login");
            sessionStorage.removeItem("stg_salesOrg");
            sessionStorage.removeItem("stg_location_code");
            sessionStorage.removeItem("stg_city");
            sessionStorage.removeItem("stg_scheme_id");
            sessionStorage.removeItem("stg_leadId");
            sessionStorage.removeItem("orderDetails");
            sessionStorage.removeItem("createNewAccountActive");
            sessionStorage.removeItem("mo_acq_numberStatus");
            sessionStorage.removeItem("stg_acq_otp");
            sessionStorage.removeItem("stg_acq_userType");

            // Redirect to new customer page
            window.location = '/newCustomer';
        } else {
            // Redirect to home page
            window.location = '/';
        }
    };

    const goBackPage = () => {
        handleStateChange('schemePage', true);
        handleStateChange('checkout', false);
    };

    const renderSchemeImages = (publications) => {
        const duplicateImageRemoved = duplicateImageFilter(publications);
        const filteredText = schemeDescription(publications);
        const [printText, digitalText] = filteredText;

        return duplicateImageRemoved.map((item, index) => (
            <div className={item.classNameCSS} key={index}>
                {index > 0 && <span className="planAdditionSelected">+</span>}
                <img src={item.image} alt="logo" className="img-fluid" />
            </div>
        ));
    };

    const clickOnSelect = (id) => {
        const selectedScheme = compoPlan.find((scheme) => scheme.id === id);

        if (!state.couponNotAdded) {
            handleStateChange('removeCouponCodePopup', true);
            sessionStorage.setItem("stg_selectPlan_info", id);
            handleStateChange('showPopupAlertStateRemoveCoupon', true);
            return;
        }

        // Update the current plan details
        handleStateChange('currentPlanDuration', selectedScheme.duration);
        handleStateChange('currentPlanPrice', selectedScheme.price);
        handleStateChange('actualPlanPrice', selectedScheme.price);
        handleStateChange('currentPlanPublication', selectedScheme.publications);
        handleStateChange('currentPlanId', selectedScheme.id);

        sessionStorage.setItem("stg_selectedPlan_Name", selectedScheme.publications);
        sessionStorage.setItem("stg_selectedPlan_Duration", selectedScheme.duration);
        sessionStorage.setItem("stg_selectedPlan_Price", selectedScheme.price);

        compoPlan.push({
            id: state.currentPlanId,
            publications: state.currentPlanPublication,
            duration: state.currentPlanDuration,
            price: state.currentPlanPrice,
        });

        compoPlan.splice(compoPlan.findIndex((scheme) => scheme.id === id), 1);

        getPlanNameOnSummary(selectedScheme.publications);
        handleStateChange('triggerSelectPlan', false);
        sessionStorage.removeItem("stg_selectPlan_info");
    };
    

    const getPlanNameOnSummary = (publicationName) => {
        handleStateChange('planNameOnSummary', planNames[publicationName] || "Hindustan Times Newspaper");
    };

    const getExitingPlan = () => {
        const selectedSchemes = state.selectedSchemes;
        if (selectedSchemes.currentSchemes.length !== 0) {
            selectedSchemes.currentSchemes.forEach((scheme) => {
                handleStateChange('currentPlanPublication', scheme.publications);
                handleStateChange('currentPlanDuration', scheme.duration);
                handleStateChange('currentPlanPrice', scheme.price);
                handleStateChange('actualPlanPrice', scheme.price);
                handleStateChange('currentPlanId', scheme.id);
                handleStateChange('tncValueStatus', scheme.tncLink);
                getPlanNameOnSummary(scheme.publications);

                // Update session storage
                sessionStorage.setItem("stg_selectedPlan_Name", scheme.publications);
                sessionStorage.setItem("stg_selectedPlan_Duration", scheme.duration);
                sessionStorage.setItem("stg_selectedPlan_Price", scheme.price);
            });
        } else {
            const defaultScheme = selectedSchemes.comboSchemes[0];
            handleStateChange('currentPlanPublication', defaultScheme.publications);
            handleStateChange('currentPlanDuration', defaultScheme.duration);
            handleStateChange('currentPlanPrice', defaultScheme.price);
            handleStateChange('actualPlanPrice', defaultScheme.price);
            handleStateChange('currentPlanId', defaultScheme.id);
            handleStateChange('tncValueStatus', defaultScheme.tncLink);
            getPlanNameOnSummary(defaultScheme.publications);

            // Update session storage
            sessionStorage.setItem("stg_selectedPlan_Name", defaultScheme.publications);
            sessionStorage.setItem("stg_selectedPlan_Duration", defaultScheme.duration);
            sessionStorage.setItem("stg_selectedPlan_Price", defaultScheme.price);
        }
    };

    const getCompoOtherPlan = () => {
    const compoPlan = []; // Reset compoPlan for new computation

    const selectedSchemes = state.selectedSchemes;

    if (selectedSchemes.currentSchemes.length !== 0) {
        selectedSchemes.comboSchemes.forEach((scheme) => {
            if (sessionStorage.getItem("createNewAccountActive") === "ON") {
                if (!scheme.publications.includes(";")) {
                    compoPlan.push({
                        id: scheme.id,
                        publications: scheme.publications,
                        duration: scheme.duration,
                        price: scheme.price,
                    });
                }
            } else {
                compoPlan.push({
                    id: scheme.id,
                    publications: scheme.publications,
                    duration: scheme.duration,
                    price: scheme.price,
                });
            }
        });
    } else {
        selectedSchemes.comboSchemes.forEach((scheme) => {
            if (sessionStorage.getItem("createNewAccountActive") === "ON") {
                if (!scheme.publications.includes(";")) {
                    compoPlan.push({
                        id: scheme.id,
                        publications: scheme.publications,
                        duration: scheme.duration,
                        price: scheme.price,
                    });
                }
            } else {
                compoPlan.push({
                    id: scheme.id,
                    publications: scheme.publications,
                    duration: scheme.duration,
                    price: scheme.price,
                });
            }
        });

        // Remove the first element if no current schemes
        compoPlan.shift();
    }

    return compoPlan; // Return the computed compoPlan
    };

    const paymentInit = async () => {
        try {
            const { currentPlanId, accountId, isCheckedTnc, paymentOption} = state;
            console.log("forInstitutional new == ", forInstitutional);

            if (!isCheckedTnc) {
                showPopUpAlert(true);
                return;
            }
    
            if (!paymentOption.gateway) {
                notify("Please choose a payment option");
                return;
            }
            handleStateChange('loading', true);
            const additionalData = await UserService.existingCustomerLeadGenerate(accountId, currentPlanId, forInstitutional);
            console.log("additionalData ============= ", additionalData.data.lead_id);
            if (additionalData && additionalData.data && additionalData.data.lead_id) {
                handleStateChange('leadId', additionalData.data.lead_id);

                // paymentDoneSuccess();
                
            }else{
                notify("Oops! You’re not eligible for a new booking as an existing lead is already registered under this scheme.");
                handleStateChange('loading', false);
                return;
            }
        } catch {
            notify("Failed! Check your network");
            handleStateChange('loading', false);
        }
    }
    
    const paymentDoneSuccess = async () => {
        const { randomUUId, currentPlanPrice, mobile, currentPlanId, paymentOption, updateEmail, couponCodeValue, leadId, isCheckedTnc } = state;
        // console.log("isCheckedTnc =============   ", isCheckedTnc);
        if (!isCheckedTnc) {
            showPopUpAlert(true);
            return;
        }

        if (!paymentOption.gateway) {
            notify("Please choose a payment option");
            return;
        }

        handleStateChange('loading', true);

        try {
            const couponCode = state.couponNotAdded ? '' : couponCodeValue.trim();
            const response = await UserService.paymentTransaction(
                randomUUId,
                currentPlanPrice,
                mobile,
                currentPlanId,
                 `fresh_${mobile}`,
                paymentOption.gateway,
                updateEmail,
                couponCode,
                leadId || null,
                state.utmSource
            );
            console.log("dsdsdsfds ", response);
            if (response.success) {
                handleStateChange('transToken', response.txnToken);
                handleStateChange('paytmPageShow', true);
                ReactGA.ga('send', 'pageview', '/paymentPage');
            } else {
                notify(response.message);
                handleStateChange('loading', false);
            }
        } catch {
            notify("Failed! Check your network");
            handleStateChange('loading', false);
        } 
        // finally {
        //     handleStateChange('loading', false);
        // }
    };

    const paymentDoneSuccessForAqu = async () => {
        const couponCodeValue = state.couponNotAdded ? "" : state.couponCodeValue.trim();
        const { updateEmail, isCheckedTnc, paymentOption, currentPlanPrice, randomUUId, mobile, currentPlanId, utmSource } = state;
        console.log("paymentDoneSuccessisCheckedTnc == ", isCheckedTnc);
        sessionStorage.setItem("stg_mo_redirectFrom", "Click on Checkout Button From Checkout Page");
        sessionStorage.setItem("stg_emailAdress_Checkout_updated", updateEmail);

        if (!updateEmail) {
        notify("Please enter your corporate email Id");
        return;
        }

        if (!validator.isEmail(updateEmail)) {
        notify("Please enter a valid email Id.");
        return;
        }

        if (!isCheckedTnc) {
            showPopUpAlert(true);
            return;
        }

        if (!paymentOption.gateway) {
        notify("Please choose a payment option.");
        return;
        }

        try {
        handleStateChange('loading', true);
        const response = await UserService.paymentTransaction(
            randomUUId,
            currentPlanPrice,
            mobile,
            currentPlanId,
            `fresh_${mobile}`,
            paymentOption.gateway,
            updateEmail,
            couponCodeValue,
            null,
            utmSource
        );

        if (response.success) {
            handleStateChange('transToken', response.txnToken);
            handleStateChange('paytmPageShow', true);
            ReactGA.ga('send', 'pageview', '/paymentPage');
        } else {
            notify(response.message);
        }
        } catch (error) {
        notify("Failed! Check your network.");
        } finally {
        handleStateChange('loading', false);
        }
    };

    return (
        <section>
            {state.schemePage && (
                <div>
                    <div className="container">
                        {sessionStorage.getItem("stg_loggedInHeader") === "ON" ? (
                            <LoggedInHeader />
                        ) : (
                            <AcqHeader />
                        )}
                        {state.loading && <PageLoader />}
                    </div>
                    <div className="absolute-center">
                        <div className="container mb-5">
                            <div className="row">
                                <div className="col-md-12 breadcrumbs">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <a href="/" onClick={redirectToHome}>Home</a>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">Order List</li>
                                        </ol>
                                    </nav>
                                </div>
                                <div className="col-md-12 order-title">
                                    <div className="order-back-btn">
                                        <span className="back-btn-pincode">
                                            <FontAwesomeIcon icon={faArrowLeft} size="2x" className="faArrowLeft" onClick={click} />
                                        </span>
                                        <span className="order-back-title">Checkout</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                 <div className="col-md-7 blackClourV2">
                                    <div className="current-schemeCh">
                                       <div className="checkout-left-first">
                                             {sessionStorage.getItem("createNewAccountActive") === "ON" ? (
                                                <h6>You are subscribing to</h6>
                                             ) : (
                                                <h6>You are subscribing to</h6>
                                             )}
                                             <div id="duration-box">
                                                <p>{Math.floor(state.currentPlanDuration) || "NA"} Months Plan</p>
                                             </div>
                                       </div>
                                       <div className="checkout-left-second">
                                             <div className="checkout-img-main">
                                                {renderSchemeImages(state.currentPlanPublication)}
                                             </div>
                                             <div id="details-box">
                                                <p>Daily newspaper delivery</p>
                                             </div>
                                       </div>
                                    </div>
                                    {compoPlan.length > 0 && (
                                       <div className="other-scheme mt-4">
                                             <div className="header__center">Other Plans</div>
                                             {compoPlan.map((scheme, index) => (
                                                <div className="row mt-2" key={scheme.id}>
                                                   <div className="col-4 publication-details">
                                                         {renderSchemeImages(scheme.publications)}
                                                         <p>{Math.floor(scheme.duration) || "NA"} Months Plan</p>
                                                   </div>
                                                   <div className="col-4 text-center price">
                                                         <p style={{ marginTop: "10px" }}>
                                                            <i className="fa fa-inr"></i>
                                                            {scheme.price}
                                                         </p>
                                                   </div>
                                                   <div className="col-4 mt-2">
                                                         <button
                                                            className="select-btn btn"
                                                            onClick={() => clickOnSelect(scheme.id)}
                                                         >
                                                            Select
                                                         </button>
                                                   </div>
                                                </div>
                                             ))}
                                       </div>
                                    )}
                                 </div>

                                 <div className="col-md-5 summaryy-details summaryy-details-sec">
                                    <PaymentSummary
                                        planNameOnSummary={state.planNameOnSummary}
                                        actualPlanPrice={state.actualPlanPrice}
                                        couponNotAdded={state.couponNotAdded}
                                        onSelectPaymentL1={selectPaymentOfferL1}
                                        couponDiscountAmount={state.couponDiscountAmount}
                                    />
                                    <div className="bottom-tab">
                                        <div className="total-price">
                                            <p className="text-center blackClourV2">
                                                Total (inc. GST) : Rs. {state.currentPlanPrice}
                                            </p>
                                        </div>
                                        <div className="terms mt-1">
                                            <input
                                                type="checkbox"
                                                name="terms"
                                                id="terms"
                                                checked={state.isCheckedTnc}
                                                onChange={handleTncChange}
                                            />
                                            <label htmlFor="terms">
                                                I Agree with
                                                <a href={state.tncValueStatus} className="">
                                                    <span>{" Terms"} &amp; {"Conditions"}</span>
                                                </a>
                                            </label>
                                        </div>
                                        <PopupAlert
                                            showModalPopup={state.showPopupAlertState}
                                            onPopupClose={() => setState((prev) => ({ ...prev, showPopupAlertState: false }))}
                                        />
                                        {sessionStorage.getItem("createNewAccountActive") === "ON" ? (
                                          <div className="checkout text-center">
                                             <button
                                                   className="btn checkout-btn"
                                                   onClick={paymentDoneSuccessForAqu}
                                                   data-toggle="modal"
                                                   data-target="#myModall"
                                             >
                                                   CHECKOUT
                                             </button>
                                          </div>
                                       ) : (
                                          <div className="checkout text-center">
                                             <button
                                                   className="btn checkout-btn"
                                                   onClick={paymentInit}
                                                   data-toggle="modal"
                                                   data-target="#myModall"
                                             >
                                                   CHECKOUT
                                             </button>
                                          </div>
                                       )}

                                        {/* <ToastContainer /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {state.paytmPageShow && state.paymentOption.gateway && (
                state.paymentOption.gateway === "Paytm" ? (
                    <ShowPaytmPaymentPage txnToken={state.transToken} />
                ) : (
                    <ShowRazorPayPaymentPage
                        txnToken={state.transToken}
                        mobile={state.mobile}
                        email={state.updateEmail}
                        name={state.selectedSchemesInfo.customerName}
                        price={state.currentPlanPrice}
                        onDismissRazorpay={onDismissRazorpay}
                    />
                )
            )}
        </section>
    );
};

export default NewSchemes;
