import React, { Component } from 'react';
import '@fontsource/poppins';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './../css/Style.css';
import otpnjTimer from './../images/otpnj-timer.svg';
import UserService from '../services/UserService';
import OtpInput from 'react-otp-input';
import AllSchemes from './AllSchemes';
import PageLoader from '../subComponents/PageLoader';
import Captcha from '../subComponents/Captcha';
import { ToastContainer, toast } from 'react-toastify';
import ReactGA from 'react-ga';
import { createGAEventTracker } from "../hooks/createGAEventTracker";



var captchaCounter=0;
// let GAEventsTracker = useGAEventTracker("OTP Page");

export default class OtpPageNJ extends Component {


    constructor(props) {
        super(props);
        this.state = 
        {
            loginInfo: this.props,
            otp: '',
            userInfo: '',
            loginPage: true,
            isLoggedIn: false,
            loading: false,
            delay:true,
            wrongOtp:false,
            showCheckout:false,
            showCaptcha:false,
            showOtpSection:true,
            showIncorectOtpMsg:"Incorrect OTP*",

        };
        this.GAEventsTracker = createGAEventTracker("OTP Page");
        this.handleChange = otp => this.setState({ otp });
        this.abortController = null;
    }

    defultAlert = (msg) => toast.warn(msg, {
        position: toast.POSITION.TOP_CENTER
    });
    
    componentDidMount()
    {
        sessionStorage.setItem("showNextCatchaVerify","true")
        console.log("window ======================")
        if ('OTPCredential' in window) {
            this.abortController = new AbortController();
      
            navigator.credentials
            .get({
                otp: { transport: ['sms'] },
                signal: this.abortController.signal,
            })
            .then((otpCredential) => {
                console.log("otpCredential === ", otpCredential)
                this.setState({ otp: otpCredential.code }); // Automatically set OTP
            })
            .catch((err) => {
                console.error('Error fetching OTP:', err);
            });
        }

        
        // if ('OTPCredential' in window) {
        //     // Mocking OTP input (for testing)
        //     console.log('OTPCredential:', window.OTPCredential);
        //     setTimeout(() => {
        //       const otpCredential = { code: '1234' }; // Mock OTP code
        //       this.setState({ otp: otpCredential.code }); 
        //     }, 2000); // Simulate the delay of receiving OTP
        // }
    }

    componentWillUnmount() {
        // Cleanup to abort the OTP request
        console.log("abortController ======================")
        if (this.abortController) {
          this.abortController.abort();
        }
    }


    resendOtp() 
    {
            var source="SSP"
            var fresh_user = true
            this.setState({ otp: "" });
            this.setState({ wrongOtp: false });

            if(this.props.otpCallType=="fresh")
            {
                UserService.generateFreshUserOtp(this.state.loginInfo.mobile, source,fresh_user).then(response =>
                    {
                        if (response.success)
                        {
                             this.props.updateOtpTimer();
                        }
                    sessionStorage.setItem("userInfo", JSON.stringify(response.data));
                })

                this.moengageAqu_ResendBtn_Click();
            }
            else
            {
                UserService.generateOtp(this.state.loginInfo.mobile, source).then(response =>
                    {
                        if (response.success)
                        {
                             this.props.updateOtpTimer();
                        }
                    sessionStorage.setItem("userInfo", JSON.stringify(response.data));
                })

            }
    }

    moengageNextSubmissionAfterOTP_Click()
    {
      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {
        window.Moengage.update_unique_user_id(sessionStorage.getItem("stg_mobile_number"));
        window.Moengage.track_event('After OTP Next Button Click',
        {
          Campaign_Source: sessionStorage.getItem("stg_utm_source"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
          Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Registration_Status: sessionStorage.getItem("stg_status"),
          Mobile_Number: sessionStorage.getItem("stg_mobile_number"), Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
          OTP_Status :sessionStorage.getItem("stg_mo_OTP_Status")

        })
      }
    }

    moengageAqu_ResendBtn_Click()
    {
      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {
        window.Moengage.update_unique_user_id(sessionStorage.getItem("stg_mobile_number"));
        window.Moengage.track_event('Acquisition Resend OTP Button Clicked',
        {
            Campaign_Source: sessionStorage.getItem("stg_utm_source"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
            Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
            Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),   Mobile_Number_Status : sessionStorage.getItem("mo_acq_numberStatus"),


        })
      }
    }

    moengageAqu_NextSubmit_Click()
    {
      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {
        window.Moengage.update_unique_user_id(sessionStorage.getItem("stg_mobile_number"));
        window.Moengage.track_event('Acquisition NEXT button Clicked',
        {
            Campaign_Source: sessionStorage.getItem("stg_utm_source"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
            Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
            Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),   OTP_Entered : sessionStorage.getItem("stg_acq_otp"),
            Registered: sessionStorage.getItem("stg_acq_userType")
        })
      }
    }

    nextSubmit =() =>
    {
        if(this.props.otpCallType==="fresh")
        {
            this.loginFreshUser();
            this.moengageAqu_NextSubmit_Click();
            sessionStorage.setItem("stg_mo_redirectFrom","Click on Next Button on OTP Submission")
            this.GAEventsTracker("Next Button Click for Otp", "For Fresh User")

        }
        else
        {
            this.loginUser();
            this.GAEventsTracker("Next Button Click for Otp", "For Old User")
        }



    }

    loginFreshUser = () =>
    {
        sessionStorage.removeItem("stg_acq_otp")
        sessionStorage.removeItem("stg_acq_userType")
        sessionStorage.removeItem("stg_emailAdress_Checkout_updated")

        if (this.state.otp)
        {
            var mobileNum=this.state.loginInfo.mobile
            var source="SSP"
            var otp=this.state.otp
            var scheme_id=sessionStorage.getItem("stg_scheme_id")
            sessionStorage.setItem("stg_acq_otp",otp)
            this.setState({ loading: true });
            UserService.verifiyAcqFreshUserOtp(mobileNum,source,otp,scheme_id).then(response => {
                if (response !== "")
                {
                    sessionStorage.setItem("selectedSchemes", JSON.stringify(response.schemes[0]));
                    sessionStorage.setItem("default_gateway", "Razorpay")

                     if(response.addressDetails !== null)
                     {
                        sessionStorage.setItem("stg_acq_exitingUser","ON")
                        sessionStorage.setItem("orderDetails", JSON.stringify(response));
                        sessionStorage.removeItem("stg_acq_freshUser")
                        sessionStorage.setItem("stg_acq_userType","Exiting User")
                        sessionStorage.setItem("stg_emailAdress_Checkout_updated",response.email);
                    }
                    else
                    {
                        sessionStorage.setItem("stg_acq_userType","New User")
                        sessionStorage.removeItem("stg_acq_exitingUser")
                        sessionStorage.setItem("stg_acq_freshUser","ON")
                    }
                    this.props.gotCheckout();
                    ReactGA.ga('send','pageview','/getNewPlan')
             }
            }).catch(err =>
                {
                    console.log("33333", err);
                    this.defultAlert("Something went wrong!");
                    this.setState({ loading: false });
                    this.setState({ wrongOtp: true });
            })
        }
        else
        {
            this.enterOtp();
            this.setState({ loading: false });
        }
    }


    loginUser = () => 
    {
        if (this.state.otp) 
        {
            this.setState({ loading: true });

            // this.setState({ showIncorectOtpMsg: "Verifing OTP..........." });

            UserService.verifiyOtp(this.state.otp, this.state.loginInfo.mobile).then(response => {

                if (response.data !== "") 
                {
                    sessionStorage.removeItem("showHeader");
                    sessionStorage.setItem("showHeader","ON")
                    sessionStorage.setItem("stg_loggedInHeader","ON")
                    sessionStorage.setItem("orderDetails", JSON.stringify(response.data));
                    this.props.gotpHome();
                    sessionStorage.setItem("default_gateway", response.data.defaultGateway)
                    sessionStorage.setItem("stg_vendor_name",response.data.vendorName)
                    sessionStorage.setItem("stg_vendor_mobile",response.data.vendorMobile);
                    sessionStorage.setItem("stg_vendor_code",response.data.vendorCode);
                    // sessionStorage.setItem("journey", "new")
                    this.setState({ userInfo: response });
                    this.setState({ isLoggedIn: true });
                    this.setState({ loading: false });
                    this.setState({ loginPage: false });
                    sessionStorage.setItem("stg_mo_OTP_Status","Correct OTP")
                    sessionStorage.setItem("stg_mo_redirectFrom","Click on Next Button After Correct OTP")
                    this.moengageNextSubmissionAfterOTP_Click();
                    // this.useInterval();
                    ReactGA.ga('send','pageview','/dashboard')
                    sessionStorage.setItem("stg_mo_OTP_Status","Correct OTP")
                    sessionStorage.setItem("stg_mo_redirectFrom","Click on Next Button After Correct OTP")
                    this.moengageNextSubmissionAfterOTP_Click();
                    // this.useInterval();
             }

            }).catch(err => 
                {
                    // this.setState({ showIncorectOtpMsg: "Incorrect OTP*" });
                    // console.log("Error Status:", err.response.status);
                    // console.log("Error Message:", err.response.data.message);
                    // console.log("No response received", err.request);
                    // console.log("Error", err.message);
                    this.setState({ loading: false });
                    this.setState({ wrongOtp: true });
                    this.moengageNextSubmissionAfterOTP_Click();
                    if(err.response){
                        if(err.response.status===429)
                        {
                            this.defultAlert("You have entered incorrect OTP multiple times. Please try after 30 minutes.");
                            this.setState({ loginPage: false });

                        }
                        else
                        {
                            this.defultAlert(err.response.data.message || "Something went wrong!");
                            sessionStorage.setItem("stg_mo_OTP_Status","Wrong OTP")
                            captchaCounter++;
                            if(captchaCounter>2)
                            {
                                sessionStorage.setItem("showNextCatchaVerify","false");
                                this.setState({ showCaptcha: false });
                            }

                        }
                    }
            })
        }
        else
        {
            this.enterOtp();
            this.setState({ loading: false });
            sessionStorage.setItem("stg_mo_OTP_Status","Wrong OTP")
            this.moengageNextSubmissionAfterOTP_Click();
        }
    }
    
    click = () => 
    {
        this.props.otpCallBack();
    }
    newCustomer = () => 
    {
        this.props.newUser();
    }

    enterPressed(event) 
    {
        var code = event.keyCode || event.which;
        if (code === 13) 
        {
            this.loginUser();
        }
    }

    render() {
        const { loading } = this.state;

        return (
            <div>
                { loading && <PageLoader/> }
                {this.state.loginPage ?
                (
                    <div>
                        <div className="promptV2 labelFontSize1V1 fontNJ row">
                            <div className="col-6 col-md-6 text-left">
                                <span className="font-otp-nj">Please enter OTP*</span>
                            </div>

                            {/* {this.state.wrongOtp && (
                                <div className="incorrectOtp1 text-center">
                                    <span className="otp-error">{this.state.showIncorectOtpMsg}</span>
                                </div>
                            )} */}

                            {this.props.timerDelay && (
                            <div className="col-6 col-md-4 text-right">
                                <p className="resnedOtp fontNJ text-right">
                                    <img src={otpnjTimer} height="20px" width="15px" className='mr-1' />
                                    00:{this.props.otpTimer}
                                </p>
                            </div>
                            )}
                        
                            {!this.props.timerDelay && ( 
                            <div className="col-6 col-md-4 text-right">
                                <p onClick={() => this.resendOtp()}> <a className="resnedOtp fontNJ text-right" >Resend OTP</a>
                                </p>
                            </div>
                            )}
                            
                        </div>
                        <div className="otpboxV2 fontNJ mb-4">
                            <OtpInput
                                value={this.state.otp}
                                onChange={this.handleChange}
                                numInputs={4}
                                isInputNum={true}
                                shouldAutoFocus={true}
                                separator={<span > ---- </span>}
                                // onKeyPress={this.enterPressed.bind(this)}
                                autoComplete="one-time-code"
                                containerStyle={false}
                                disabledStyle={true}
                                className="otp-input-b"
                                inputStyle={{ 'width': "5.0em", "height":"3em", "textAlign":"center", "background": "#FFFFFF 0% 0% no-repeat padding-box", "border": "1px solid #CDCDCD", "borderRadius": "8px"}}
                            />
                        </div>
                        {this.state.showCaptcha &&
                            <Captcha nextBtnClock={this.nextSubmit}/>
                        }
                        <div className="getOtpBtn setOtpBtn">
                            {/* <div className="d-flex justify-content-center mt-2 mb-2">
                                <a className="clickV2-alink" onClick={this.newCustomer}>New Customer</a> 
                            </div> */}
                                {sessionStorage.getItem("showNextCatchaVerify")=== "true" &&
                                <div>
                                    {this.state.otp.length<4 ? (
                                        <button disabled={true} type="button" className="btn dislogin-btnV2 fontNJ">
                                        NEXT</button>
                                        ):(
                                        <button disabled={false} type="button" className="btn login-btnV2 fontNJ" onClick={() => this.nextSubmit()}>
                                        NEXT</button>
                                        )
                                    }
                                  </div>
                                }
                        </div>
                     </div>

                ):
                (
                    <div>
                        <h6 style={{color: "Red"}}>You have entered incorrect OTP multiple times. Please try after 30 minutes.</h6>
                    </div>
                )
                }
                {this.state.showCheckout &&
                <div>
                    <AllSchemes/>
                </div>
            }
            </div>
        );
    }
}