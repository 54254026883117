import React, { useState, useEffect } from 'react';
import mobileNo from '../images/profile/mobile_no.svg';
import addressBook from '../images/profile/address_book_contact_icon.svg';
import newspaperIcon from '../images/profile/newspaper_clipping_icon.svg';
import nameIcon from '../images/profile/name-icon.svg';
import editIcon from './../images/edit_write_pen_icon.svg';
import '@fontsource/poppins';
import '../css/Style.css';
import UserService from '../services/UserService';
import {validateCorporateEmail} from "../common/commonFuntion";
import { ToastContainer, toast } from 'react-toastify';
import VendorDetailsChange from '../components/VendorDetailsChange';
import ChangeAddressDetails from '../components/ChangeAddressDetails';
import PopupAddressChange from '../subComponents/PopupAddressChange';
import Button from 'react-bootstrap/Button';
import ReactGA from 'react-ga';
import manProfileImg from "../images/Orders/man.png";
import ProfileIcon from '../components/ProfileIcon';
import PageLoader from '../subComponents/PageLoader';
import EailVerify from '../subComponents/EailVerify';
import Accordion from '@material-ui/core/Accordion';
import AccordionActions from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const CORPORATE_STATUS = process.env.REACT_APP_CORPORATE_STATUS === 'true';


const SideProfile = ({ onLocationIdReceived, updateCorporateAccount }) => {

    const userInfo = JSON.parse(sessionStorage.getItem('orderDetails')) || {
      customerName: '',
      mobile: '',
      address: '',
      email: '',
    };

    const [vendorCode, setVendorCode] = useState(sessionStorage.getItem('stg_vendor_code'));
    const [vendorName, setVendorName] = useState(sessionStorage.getItem('stg_vestg_vendor_namendor_code'));
    const [vendorMobile, setVendorMobile] = useState(sessionStorage.getItem('stg_vendor_mobile'));
    const [editEmail, setEditEmail] = useState(userInfo.email);
    const [emailChange, setEmailChange] = useState(userInfo.email);
    const [emailTextBox, setEmailTextBox] = useState(true);
    const [editableTextBox, setEditableTextBox] = useState(false);
    const [homePage, setHomePage] = useState(true);
    const [changeVendorDetailsShow, setChangeVendorDetailsShow] = useState(false);
    const [showAddressPopup, setShowAddressPopup] = useState(false);
    const [showPopupAlertState, setShowPopupAlertState] = useState(false);
    const [showPopupMsgState, setShowPopupMsgState] = useState('');
    const [expanded, setExpanded] = useState(true);
    const [otpSent, setOtpSent] = useState(false);
    const [loading, setLoading] = useState(false);
    // Using Material-UI's hook to detect mobile view
    const isMobile = useMediaQuery('(max-width:768px)');

    const VendorDetailsChangeRef = React.createRef();

    // Automatically collapse the accordion when isMobile is true
    useEffect(() => {
      if (isMobile) {
        setExpanded(false); // Collapse when switching to mobile view
      }else{
        setExpanded(true);
      }
    }, [isMobile]);

    const updateOrderSession = (emaiId) => {
      // Get the current session value (JSON string)
      let orderDetailsOnly = sessionStorage.getItem('orderDetails');
  
      // Parse the string to convert it into an object
      if (orderDetailsOnly) {
         orderDetailsOnly = JSON.parse(orderDetailsOnly);
  
         // Modify the specific key (e.g., update age)
         if(emaiId){
            orderDetailsOnly.email = emaiId;

            // Save the updated object back to sessionStorage
            sessionStorage.setItem('orderDetails', JSON.stringify(orderDetailsOnly));
            // console.log("Updated session value:", updatedOrders);
         }
      }
    };

    const handleExpandClick = (event) => {
      // Stop propagation to prevent expanding when clicking elsewhere
      event.stopPropagation();
      setExpanded((prev) => !prev);
    };
    const changeVendorDetails = () => {
      if (VendorDetailsChangeRef.current) {
        VendorDetailsChangeRef.current.handleShow();
      }

      // const vendorInfo = JSON.parse(sessionStorage.getItem("vendorInfo"));
      // const vendorUpdated = sessionStorage.getItem("stg_vendorUpdated") === 'true';
      setVendorCode(sessionStorage.getItem("stg_vendor_code"));
      setVendorName(sessionStorage.getItem("stg_vendor_name"));
      setVendorMobile(sessionStorage.getItem("stg_vendor_mobile"));
    };

    const corporateAccount = (email) => {
      if (updateCorporateAccount) {
        updateCorporateAccount(email);
      } else {
        console.log('No function passed');
      }
    };
    
    // const changeVendorDetails = () => {
    //   setHomePage(false);
    //   setChangeVendorDetailsShow(true);
    // };

    const toggleAddress = () => {
        setShowAddressPopup(!showAddressPopup);
        ReactGA.ga('send','pageview','/changeAddressDetails');
    }

    const onEscPress = (e) => {
      setShowAddressPopup(!showAddressPopup);
      ReactGA.ga('send','pageview','/changeAddressDetails');
    }

    const gotoHomePage = () => {
      setHomePage(true);
      setChangeVendorDetailsShow(false);
    };

    const edit = () => {
      setEmailTextBox(false);
      setEditableTextBox(true);
      setEditEmail(emailChange);
    };

    const canceled = () => {
      setEmailTextBox(true);
      setEditableTextBox(false);
    };

    const handleChange = (e) => {
      setEditEmail(e.target.value);
    };

    useEffect(() => {
      const fetchAccountInfo = async () => {
        try {
          const response = await UserService.fetchAccountInfo(userInfo.mobile);
          setEmailChange(response.data.email);
          setEditEmail(response.data.email);
          corporateAccount(response.data.email);
          // setUserInfo((prevState) => ({
          //   ...prevState
          // }));
          const locationId = response?.data?.location?.id;
          if (locationId !== undefined) {
            onLocationIdReceived(locationId);
          }
        } catch (err) {
          console.error(err);
        }
      };

      fetchAccountInfo();
    }, [userInfo.mobile, onLocationIdReceived]);

    const enterPressed = (event) => {
      const code = event.keyCode || event.which;
      if (code === 13) {
        ValidateEmail();
      }
    };
    const showPopUpAlert = (status, msg) => {
      setShowPopupAlertState(status);
      setShowPopupMsgState(msg);
    };

    const ValidateEmail = async() => {
      const email = editEmail;
      if (email !== "") {
        const res = await saveEmail();
        console.log("res === ", res);
        if(res){
          setEditEmail(email);
          setOtpSent(true);
        }else{
          setOtpSent(false);
          return;
        }

      }
    };

    // Update email address
    const updateEmailAddress = async() => {
      setLoading(true);
      const email = editEmail;
      if (email !== "") {
        const mobile = userInfo.mobile;
        UserService.updateEmail(email, mobile)
          .then( async(response) => {
            if (response.data.success) {
              setEmailTextBox(true);
              setEditableTextBox(false);
              setEmailChange(email);
              setEditEmail(email);
              corporateAccount(email);
              updateOrderSession(email);
              setLoading(false);

              // setUserInfo((prevState) => ({
              //   ...prevState
              // }));
              emailUpdated();
            } else {
              setLoading(false);
              emailIdNotValid();
            }
          })
          .catch((err) => {
            setLoading(false);
            if(err?.response?.data?.message){
              defultAlert(err?.response?.data?.message);
            }else{
              notFound();
            }
          });
      } else {
        setLoading(false);
        enterEmail();
      }
    };

    const saveEmail = async (sentType=false) =>{
      setLoading(true);
      try {
        const otpResponse = await UserService.generateFreshEamilOtp(editEmail, "SSP");
      
        if (otpResponse?.success) {
          defultAlert(sentType
            ? "OTP has been resent successfully. Please check your email."
            : "OTP has been sent successfully to your registered email.");
          setLoading(false);
          return true;
  
        }else if(otpResponse?.message){
          defultAlert(otpResponse.message);
          setLoading(false);
          return false;
        }
        else{
          defultAlert(`${otpResponse.message}`);
          setLoading(false);
          return false;
        }
      
      } catch (error) {
        console.error("Error in after login checkMobileExistWithEvent:", error);
        defultAlert(error);
        setLoading(false);
        return false;
      }
      
    }

    const handleSummaryClick = (event) => {
      event.stopPropagation(); // Prevent the default expansion on summary click
    };

    const handleClose = (status) => {
      setOtpSent(status);
    };

    // Toast notifications
    const defultAlert = (msg) => toast.warn(msg || "Some network issues, Please try again", { position: toast.POSITION.TOP_CENTER });
    const notFound = () => toast.warn("Some network issues, Please try again", { position: toast.POSITION.TOP_CENTER });
    const emailUpdated = () => toast.success("Email has been updated", { position: toast.POSITION.TOP_CENTER });
    const emailIdNotValid = () => toast.warn("Please enter a valid email", { position: toast.POSITION.TOP_CENTER });
    const networkIssue = () => toast.warn("Some network issue, Please try again!", { position: toast.POSITION.TOP_CENTER });
    const enterEmail = () => toast.warn("Please enter your email Id", { position: toast.POSITION.TOP_CENTER });

    return (
      <>
      { loading && <PageLoader/> }
      <div className="text-center card-box profilecard">
        {/* defaultExpanded */}
        <Accordion expanded={expanded} className="order-item">
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                onClick={isMobile ? handleExpandClick : (e) => e.stopPropagation()} // Only toggle on mobile view
                style={{ cursor: isMobile ? 'pointer' : 'default', display: isMobile ? 'block' : 'none' }} // Disable on full view
              />
            }
            aria-controls={`panel${1}-content`}
            id={`panel${1}-header`}
            className="">
            <div className="member-card pt-2">
              <div className="thumb-lg member-thumb mx-auto mx-auto-mobile-none proile-img-div">
                <ProfileIcon className="rounded-circle proile-img" name={userInfo.customerName} />
                  {/* <img src={manProfileImg} className="rounded-circle proile-img" alt="profile-image" width="20%"/> */}
              </div>
              <div className="profile-card-first">
                <div className="profile-card__txt name">{userInfo.customerName || 'NA'}</div>
                {emailTextBox && <div className='profile-card__txt profile-small-txt'>
                  <span>{emailChange || 'Enter your email'} </span>
                  <span>
                    <a className="ml-2">
                      <img src={editIcon} className="ml-2" onClick={edit} ></img>
                    </a>
                  </span>
                </div>
                }
                {editableTextBox &&
                  <div className="form-inline email-box">
                      <input type="text"
                        value={editEmail || ""}
                        onChange={handleChange}
                        onKeyPress={enterPressed}
                        placeholder="Enter your email"
                        className="form-control col-lg-12 edit-email" />
                      <button onClick={ValidateEmail} className="btn btn-dark col-lg-5 mt-2">Update</button>
                      <button onClick={canceled} className="btn btn-light col-lg-5 mt-2">Cancel</button></div>
                }
              </div>
            </div>

          </AccordionSummary>

          <AccordionDetails>
            <div className="member-card">
              <div className="">
                  <div className="profile-card-second">
                    <div className="profile-card__txt profile-small-txt">
                      <span className='profile-icons-span'><img src={mobileNo} className='profile-icon-mobile'></img></span>
                      <span>{userInfo.mobile || 'NA'} </span>
                    </div>
                    <hr className="profile-divider" />
                    <div className="profile-card-loc profile-card__txt profile-small-txt">
                      <span className='profile-icons-span'>
                        <img src={addressBook} className='profile-icon-mobile'></img>
                      </span>
                      <span> {userInfo.address.replace(/^[,\s]+|[,\s]+$/g, '').replace(/,[,\s]*,/g, ',') || 'NA'} </span>
                      <span>
                        <a className="ml-2">
                          <img src={editIcon} className="ml-2" onClick={toggleAddress} ></img>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="profile-card-third">
                    <div className="profile-card__txt profile-small-txt profile-heading">Vendor Details</div>
                    <div className="profile-card__txt profile-small-txt">
                      <span className='profile-icons-span'>
                        <img src={nameIcon} className='profile-icon-mobile'></img>
                      </span>
                      <span>{sessionStorage.getItem("stg_vendor_name")}</span> 
                    </div>
                    <hr className="profile-divider" />
                    <div className="profile-card__txt profile-small-txt">
                      <span className='profile-icons-span'>
                        <img src={newspaperIcon} className='profile-icon-mobile'></img>
                      </span>
                      <span>{sessionStorage.getItem("stg_vendor_mobile")}</span>
                    </div>
                  </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion> 

        <div className='changeVendor-btn'>
            {/* <a onClick={changeVendorDetails}>Change Vendor</a> */}
            <a onClick={changeVendorDetails}>Change Vendor</a>
        </div>
          
          
          <VendorDetailsChange ref={VendorDetailsChangeRef} CouponDetailConfirm={gotoHomePage} vendorBack={true} changeVendorDetails={changeVendorDetails} />
          {showAddressPopup ?
            <ChangeAddressDetails
                text='Close Me'
                closeOnEscape={true}
                closeaddressChange={toggleAddress}
                popUpSuccess={showPopUpAlert}
                closeUsingESC={onEscPress} />
            : null}

            { showPopupAlertState && 
                <PopupAddressChange
                    showModalPopup={showPopupAlertState}
                    onPopupClose={showPopUpAlert}
                    showPopupMsg={showPopupMsgState}
                ></PopupAddressChange>
            }
            {
              otpSent &&  
              <EailVerify 
                show={otpSent} 
                handleClose={handleClose}
                otpEmail={editEmail}
                resendOtp={saveEmail}
                updateMail={updateEmailAddress}
              />
            }
      </div>
      </>
    )  
} 

export default SideProfile;