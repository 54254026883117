import React, { Component, Fragment } from 'react';  
import { Modal } from 'react-bootstrap';  
import './../css/Checkout.css';
import UserService from '../services/UserService';
import {duplicateImageFilter } from '../services/schemeTextFilter';
import ReactGA from 'react-ga';
  
let duplicateImageRemoved;
let promoOffer;
class PaymentOffer extends Component {  
    constructor(props) {  
        super(props);  
        this.state = {  
            showModal: false,
            paymentOptions: []
        };  
    }  
    
   componentDidMount() 
   {
      UserService.getPaymentOffers("All").then(response => {
         this.setState({paymentOptions: response.data.promoCodes});
      }).catch(err => {
      })
   }
  
    isShowModal = (status) => {  
        this.handleClose();  
        this.setState({ showModal: status });  
    }  
  
    handleClose = () => {  
        this.props.onPopupClose(false);  
    }  

    handleSelect(e) 
    {
        sessionStorage.setItem("stg_mo_redirectFrom","Click on Payment Offers Options From Payment Offers Pop-up Model")

        var _value = e.target.getAttribute('data-value').split('-');
        this.props.onSelectPaymentL2(_value);
        this.props.onPopupClose(false);  
    }
  
    render() 
    {  
        return (  
            <Fragment>  
                <Modal show={this.props.showModalPopup} onHide={this.handleClose}  
                    size="lg"  
                    aria-labelledby="contained-modal-title-vcenter"  
                    centered  
                >  
                    <Modal.Header closeButton>  
                        <Modal.Title id="sign-in-title" className="blackClourV2">  
                            <h4 className="text-center">Available Payment Offers</h4>
                         </Modal.Title>  
                    </Modal.Header>  
                    <Modal.Body>  
                        <div className='row'>
                        { this.state.paymentOptions.map((po, i) => 
                        (
                            <div className="col-md-4 blackClourV2" data-value={po.gateway+"-"+(po.name+i)} key={1 || i}>
                                <div className="offers-box" data-value={po.gateway+"-"+(po.name+i)}>
                                        {(() => 
                                        {
                                            promoOffer = po.name;
                                            if(promoOffer.toLowerCase().includes("mobikwik"))
                                            {
                                                promoOffer="Mobikwik"
                                            }
                                            else if(promoOffer.toLowerCase().includes("paytm"))
                                            {
                                                promoOffer="Paytm"
                                            }
                                            else if(promoOffer.toLowerCase().includes("icici"))
                                            {
                                                promoOffer="ICICI"
                                            }
                                            else if(promoOffer.toLowerCase().includes("hdfc"))
                                            {
                                                promoOffer="HDFC"
                                            }
                                            else if(promoOffer.toLowerCase().includes("razorpay"))
                                            {
                                                promoOffer="Razorpay"
                                            }
                                            else
                                            {
                                                promoOffer="Default"
                                            }
                                            duplicateImageRemoved = duplicateImageFilter(promoOffer)   
                                        })()}   
                                        <a onClick={this.handleSelect.bind(this)} data-value={po.gateway+"-"+(po.name+i)} disabled={!po.active}>
                                        {
                                            duplicateImageRemoved.map(function (item, i) 
                                            {
                                                return [<div data-value={po.gateway+"-"+(po.name+i) } ><img src={item.image}  data-value={po.gateway+"-"+(po.name+i)} className="img-fluid mt-3" width="30%"/></div>]
                                            })
                                        }        
                                        <p onClick={this.handleSelect.bind(this)} data-value={po.gateway+"-"+(po.name+i) }>{po.description}</p>
                                        <p onClick={this.handleSelect.bind(this)} data-value={po.gateway+"-"+(po.name+i) }>{po.name}</p>
                                        </a>
                                    
                                </div>
                            </div>                   
                        ))
                        }
                        </div>
                        <div className="signUp">  
                            <button type="button" className="link-button closBtn mt-5" onClick={() => this.isShowModal(true)}> Close</button>
                        </div>  
                    </Modal.Body>  
                </Modal >  
            </Fragment > 
        );  
    }  
}  
  
export default (PaymentOffer); 